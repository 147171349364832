import {
    useDisclosure,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,

} from "@chakra-ui/react"
import { Drawer, Button, Text, } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import "../../App.scss"
import { IWorkReport, IWorkResult, WiplineStatus } from "../../interfaces/service/Interfaces";
import { IDisciplineResult } from "../../interfaces/service/Interfaces";
import { IJobsheet } from "../../interfaces/service/Interfaces";
import { IWipLine } from "../../interfaces/service/Interfaces";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useWebHelper } from "../../hooks/UseWebHelper";
import { useCache } from "../../context/CacheContext";
import { IRightToCancel } from "../../interfaces/service/Interfaces";

interface IQuestions {
    id: string
    content: IQuestionContent[] | undefined
}
interface IQuestionContent {
    answer: string,
    question: string,
    type: string,
}
interface ISiteContact {
    EmailAddress: string,
    FirstName: string,
    Id: string,
    LandLine: string,
    LastName: string,
    MobileNumber: string,
    Role: string,
    Salutation: string,

}
interface INotes {
    id: string,
    created: string,
    created_by_id: string,
    object_reference: string,
    message: string,
    tag_id: string,
    tag: ITag,
}
interface ITag {
    id: string,
    object_type: string,
    read_permission: string | null,
    write_permission: string | null,
    name: string,
}



export const Questions = (props: IQuestions) => {
    const { isOpen, onOpen, onClose } = useDisclosure();


    return (
        <>
            {props.content !== undefined &&
            <>
            <Button onClick={onOpen} display={props.content.length > 0 ? "flex" : "none"} m={{ base: 2, xl: 4 }} ml={"1px"} colorScheme="messenger" variant={"solid"} textColor={"white"} bg={"var(--Able-blue)"} width={{ base: "100%", xl: "150px" }} marginBottom={{base: "10px", xl:"10px"}}>More Details</Button>
                <>
                    <Drawer onClose={onClose} isOpen={isOpen} size={{ base: "xs", xl: "md" }}>
                        <DrawerOverlay/>
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>
                                More Details<br />
                                <Text  as={"i"} fontSize={"xs"}>Below are some questions we asked the customer during the booking process</Text>

                            </DrawerHeader>
                            <DrawerBody>
                                {props.content.map(every => {
                                    return (
                                        <>
                                            <Text as={"b"}>Q: {every.question}</Text>
                                            <br />
                                            <Text as={"i"}>A: {every.answer}</Text>
                                            <br />
                                            <br />
                                        </>
                                    )

                                })}
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>

                </>
            </>
            }
        </>
    )
}