import {
    ScaleFade, useDisclosure, Table,
    Thead,
    Tbody,
    InputLeftAddon,
} from "@chakra-ui/react";
import {
    Button, Box, Select, FormControl, Input, Flex, Image, InputGroup, InputLeftElement, Text,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import "../../../App.scss"
import { JobBox } from "../../../components/jobBox";
import { useAuth } from "../../../context/AuthContext";
import loadingGif from "../../../assets/loadingIcon.gif"
import { IEngineer } from "../../../interfaces/service/IEngineer";
import { useCache } from "../../../context/CacheContext";
import { IDisciplineResult } from "../../../interfaces/service/Interfaces";
import useForceUpdate from "use-force-update";
import { FaSearch } from "react-icons/fa"
import { JobBoxHeading } from "../../../components/jobBox/jobBoxHeading";
import { WhiteboardView } from "../../../components/scheduler";
import { useWebHelper } from "../../../hooks/UseWebHelper";
import { MobileFilter } from "./mobileFilter";
import { useNavigate, useParams } from "react-router";
import 'animate.css'
import { response } from "express";


interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,

}


export const RingmasterHomePage = () => {
    const { selectedView, filter } = useParams();

    const [listItems, setListItems] = useState<IWorkResult[]>([]);
    const [listItems2, setListItems2] = useState<IWorkResult[]>([]);
    const [listItems3, setListItems3] = useState<IWorkResult[]>([]);
    const [listItems4, setListItems4] = useState<IWorkResult[]>([]);
    const [listItems5, setListItems5] = useState<IWorkResult[]>([]);
    const [ignore, setIgnore] = useState<boolean>(false);
    const [noActiveJobs, setNoActiveJobs] = useState<boolean>(true);
    const [defaultEngineer, setDefaultEngineer] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [engineerList, setEngineerList] = useState<IEngineer[]>([]);
    const [newEngineerList, setNewEngineerList] = useState<any[]>([]);
    const [search, setSearch] = useState<string>("");
    const [disabled, setDisabled] = useState<boolean>(false);
    const [grouping, setGrouping] = useState<string>(filter !== undefined ? filter : "engineer");
    const [history, setHistory] = useState<string>("7")
    const [postcodeList, setPostcodeList] = useState<string[]>([]);
    const [dateList, setDateList] = useState<string[]>([]);
    const [acceptedList, setAcceptedList] = useState<string[]>(["Accepted", "Pending"])
    const [isOnOrderList, setIsOnOrderList] = useState<string[]>(["Is on Order", "Not on Order"]);
    const [defaultView, setDefaultView] = useState<boolean>(true);
    const [areAccepted, setAreAccepted] = useState<boolean>(false);
    const [arePending, setArePending] = useState<boolean>(false);
    const [quantityOnOrder, setQuantityOnOrder] = useState<number>(0);
    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    const [view, setView] = useState<number>(selectedView !== undefined ? Number(selectedView) : 1);

    const { isOpen, onToggle } = useDisclosure()
    const auth = useAuth();
    const cache = useCache();
    const webHelpers = useWebHelper();
    const forceUpdate = useForceUpdate();
    const Navigate = useNavigate();


    useEffect(() => {
        if((grouping === "accepted" || grouping === "onorder") && view === 2){
            setGrouping("engineer");
            forceUpdate();
        }
    });

    const getWork = async () => {
        return await webHelpers.GetAsync<any | null>(
            "/api/service/contracting/contractor/outstandingwork?id=" + auth.user?.contractor_id, "sales"
        )
    }
    const getCompletedWork = async () => {
        console.log("/api/service/contracting/contractor/completedwork?id=" + auth.user?.contractor_id + "&days=" + history)
        return await webHelpers.GetAsync<any | null>(
            "/api/service/contracting/contractor/completedwork?id=" + auth.user?.contractor_id + "&days=" + history, "sales"
        )
    }
    const getWorkSearch = async () => {
        return await webHelpers.GetAsync<any | null>(
            "/api/service/contracting/contractor/outstandingwork/" + auth.user?.contractor_id + "?filter=" + search, "sales"
        )
    }
    const getRingmaster = async () => {
        return await webHelpers.GetAsync<any>(
            "/api/service/contracting/contractor/get?id=" + auth.user?.contractor_id, "sales"
        )
    }
    const getEngineers = async () => {
        return await webHelpers.GetAsync<any>(
            "/api/service/contracting/engineer/forcontractor?id=" + auth.user?.contractor_id, "sales"
        )
    }
    const getAllocation = async (appointmentId: string) => {
        return await webHelpers.GetAsync<any>(
            "/api/sales/work/allocation/appointment?id=" + appointmentId, "sales"
        )
    }
    const getEngineerId = async (allocationId: string) => {
        return await webHelpers.GetAsync<any>(
            "/api/sales/work/allocation/get?id=" + allocationId, "sales"
        )
    }
    const getEngineerName = async (engineerId: string) => {
        return await webHelpers.GetAsync<any>(
            "/api/service/contracting/engineer/get?id=" + engineerId, "sales"
        )
    }
    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);

    }

    const handleSubmit = () => {
        console.log("handling submit")
        console.log("search = ", search)
        if (search !== "") {
            getWorkSearch().then(response => {
                console.log("get work response ", response);
                if (response !== null) {
                    setNoActiveJobs(false);
                    setListItems(Object.values(response));
                    setLoading(false)
                }
                else {
                    setNoActiveJobs(true);
                }

            })
            //need to get a list of jobs unsearched for Assignable work menu
            getWork().then(response => {
                if (response !== null) {
                    setNoActiveJobs(false);
                    setListItems3(Object.values(response));
                    setLoading(false)
                }
                else {
                    setNoActiveJobs(true);
                }
            })
        }
        else {
            if (view === 2) {
                getCompletedWork().then(response => {
                    console.log("get completed work response = ", response);
                    if (response !== null) {
                        setNoActiveJobs(false);
                        setListItems(Object.values(response));
                        setLoading(false)
                    }
                    else {
                        setNoActiveJobs(true);
                    }
                })
            }
            else {
                getWork().then(response => {
                    console.log("get work response ", response);
                    if (response !== null) {
                        setNoActiveJobs(false);
                        setListItems(Object.values(response));
                        setListItems3(Object.values(response));
                        setLoading(false);
                    }
                    else {
                        setNoActiveJobs(true);
                    }
                })
            }
        }
        forceUpdate();
        setDisabled(true);

    }
    const handleSubmitWithParameter = (searchParameter: string) => {
        setSearch(searchParameter)
        console.log("handling submit")
        console.log("search = ", searchParameter)
        if (searchParameter !== "") {
            getWorkSearch().then(response => {
                console.log("get work response ", response);
                if (response !== null) {
                    setNoActiveJobs(false);
                    setListItems(Object.values(response));
                    setLoading(false)
                }
                else {
                    setNoActiveJobs(true);
                }

            })
        }
        else {
            if (view === 2) {
                getCompletedWork().then(response => {
                    console.log("get completed work response = ", response);
                    if (response !== null) {
                        setNoActiveJobs(false);
                        setListItems(Object.values(response));
                        setLoading(false)
                    }
                    else {
                        setNoActiveJobs(true);
                    }
                })
            }
            else {
                getWork().then(response => {
                    console.log("get work response ", response);
                    if (response !== null) {
                        setNoActiveJobs(false);
                        setListItems(Object.values(response));
                        setLoading(false);
                    }
                    else {
                        setNoActiveJobs(true);
                    }
                })
            }
        }
        forceUpdate();
        setDisabled(true);

    }

    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);
    const refresh = () => {
        console.log("view = ", selectedView, "filter", filter)
        getEngineers().then((res) => {
            setEngineerList(res);
        })
        getDisciplineFromId();
        setLoading(true);
        setIgnore(false);
        getRingmaster().then((res) => {
            getEngineerName(res.default_engineer_id).then((response) => {

                setDefaultEngineer(response.name);


                setListItems2([]);
                setListItems3([]);
                setListItems4([]);
                if (view === 2) {
                    getCompletedWork().then(response => {
                        console.log("get completed work response =", response);
                        if (response !== null) {
                            setNoActiveJobs(false);
                            setListItems(Object.values(response));
                            setLoading(false);
                        }
                        else {
                            setNoActiveJobs(true);
                        }
                    })
                }
                else {
                    getWork().then(response => {
                        console.log("get work response ", response);
                        if (response !== null) {
                            setNoActiveJobs(false);
                            setListItems(Object.values(response))
                            setListItems3(Object.values(response))
                            setLoading(false);
                        }
                        else {
                            setNoActiveJobs(true);
                        }
                    })
                }
            }
            )
        }
        )


        setIgnore(true);
    }
    const getDisciplineFromId = async () => {
        await webHelpers.GetAsync<IDisciplineResult[]>(
            "/api/sales/work/discipline/all", "sales"
        ).then((res) => {
            if (res !== null) {
                cache.addCacheItem<IDisciplineResult[]>("disciplines", res);
            }
        })
    }
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }
    function sortList(a: IWorkResult, b: IWorkResult) {
        if (a.RouteIndex < b.RouteIndex) {
            return -1;
        }
        if (a.RouteIndex > b.RouteIndex) {
            return 1;
        }
        return 0;
    }
    const PostCodeExtractionGranularity = {
        Area: "Area",         // Post code area, ie SS9 or SW1A
        District: "District", // Post Code District, ie SS
        SubArea: "SubArea"    /// Post Code subarea, ie SS9 1
    }
    /// <summary>
    /// Dismantles a specified post code and returns it's prefix and area based on granularity level
    /// </summary>
    /// <param name="postCode">Fully qualified post code to dismantle</param>
    /// <param name="gran">Granularity level</param>
    /// <returns>Prefix</returns>
    const getPostCode = (postCode: string, gran = PostCodeExtractionGranularity.Area) => {
        let sb = "";
        let maxPreFixLength = 0;
        let i = 0
        let fqPostCode = false;
        postCode = postCode.replace(" ", "");
        if (postCode.length >= 5 && postCode.length <= 7) {
            maxPreFixLength = postCode.length - 3;
            fqPostCode = true
        }
        else {
            maxPreFixLength = postCode.length;
        }
        for (let char of postCode) {
            if (!(char.match(/[a-z]/i))) { //if char is not a letter
                if (gran === PostCodeExtractionGranularity.SubArea) {
                    break;
                }
            }

            sb = sb + char;
            i++;
            if (i === maxPreFixLength) { break; }
        }
        if (gran === PostCodeExtractionGranularity.SubArea && fqPostCode) {
            sb = sb + " ";
            sb = sb + postCode[maxPreFixLength]
        }
        return sb

    }

    const IsOutwardCode = (pc: string) => {
        return pc.match("^([a-zA-Z])([a-zA-Z]?)$");
    }
    const IsIncludingOutwardCode = (pc: string) => {
        return pc.match("^([a-zA-Z])([a-zA-Z]?)(\d{1,2})([azAZ]?)$");
    }
    const IsFullPostCode = (pc: string) => {
        return pc.match("([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s");
    }
    useEffect(() => {
        engineerList.forEach(element => {
            let noOfJobs = listItems2.filter(item => item.EngineerName.indexOf(element.name) > -1).length;
            const elementIndex = engineerList.findIndex((each) => each.name === element.name)
            const updatedElement = { ...engineerList[elementIndex], assigned_jobs: noOfJobs };
            const newArray = engineerList.slice();
            newArray[elementIndex] = updatedElement;
            engineerList[elementIndex] = updatedElement;
            //@ts-ignore
        });
        listItems2.forEach(element => {
            if (!(postcodeList.includes(getPostCode(element.PostCode, PostCodeExtractionGranularity.Area)))) {
                postcodeList.push(getPostCode(element.PostCode, PostCodeExtractionGranularity.Area));
                forceUpdate();
            }
            let date = (element.AppointmentDate).split("").slice(8, 10).join('') + "/" + (element.AppointmentDate).split("").slice(5, 7).join('') + "/" + (element.AppointmentDate).split("").slice(0, 4).join('')
            if (!(dateList.includes(date))) {
                dateList.push(date);
                forceUpdate();
                console.log(dateList)
            }
        });

        forceUpdate();
    }, [listItems, listItems2]);

    useEffect(() => {
    }, [grouping])

    useEffect(() => {
        // list of active work
        setListItems2([]);
        //list of work allocated but not accepted
        setListItems5([]);
        //list of jobs that need to be accepted 
        setListItems4([]);
        listItems.forEach(element => 
            {
            element.ringmaster = defaultEngineer;
            if (element.IsOnOrder) {
                setQuantityOnOrder(1);
            }
            cache.lookUpCacheItem<IDisciplineResult[]>("disciplines")?.forEach(eachDiscipline => {
                if (eachDiscipline.id === element.DisciplineId) {
                    element.disciplineName = eachDiscipline.name;
                    element.disciplineAccent = eachDiscipline.accent;
                }
            });
            if (element.IsAccepted === true && auth.user?.contractor_type === 0) {
                element.isActive = true;
                if (element.EngineerId === auth.user?.engineer_id) {
                    setAreAccepted(true);
                    setListItems2(listItems2 => [...listItems2, element]);
                }
            }

            else if (element.IsAccepted === false && !(auth.user?.contractor_type)) { //if not accepted AND not a ringmaster
                if ((element.EngineerId === auth.user?.engineer_id)) { //if the work has been assigned to them
                    element.isActive = false;
                    setArePending(true);
                    setListItems4(listItems2 => [...listItems2, element]);
                }
            }
            else if (element.IsAccepted === false && auth.user?.contractor_type) { //if not accepted but the user IS a ringmaster
                //check if the job has already been assinged to another engineer for acceptance
                if (element.EngineerId === auth.user?.engineer_id) { //if work is not accepted AND is currently allocated to the ringmaster
                    element.isActive = false;
                    setArePending(true);
                    setListItems4(listItems2 => [...listItems2, element]);
                }
                else { //if the work has not been accepted BUT it has been assigned then 
                    element.isActive = true;

                    setListItems2(listItems2 => [...listItems2, element]);
                }

            }
            else {
                element.isActive = true;
                setAreAccepted(true);
                setListItems2(listItems2 => [...listItems2, element]);

            }
            forceUpdate();
        }, [listItems, view]);
        
        listItems3.forEach(element => 
            {
            element.ringmaster = defaultEngineer;
            if (element.IsOnOrder) {
                setQuantityOnOrder(1);
            }
            cache.lookUpCacheItem<IDisciplineResult[]>("disciplines")?.forEach(eachDiscipline => {
                if (eachDiscipline.id === element.DisciplineId) {
                    element.disciplineName = eachDiscipline.name;
                    element.disciplineAccent = eachDiscipline.accent;
                }
            });
            if (element.IsAccepted === true && auth.user?.contractor_type === 0) {
                element.isActive = true;
                if (element.EngineerId === auth.user?.engineer_id) {
                    setAreAccepted(true);
                    //setListItems2(listItems2 => [...listItems2, element]);
                }
            }

            else if (element.IsAccepted === false && !(auth.user?.contractor_type)) { //if not accepted AND not a ringmaster
                if ((element.EngineerId === auth.user?.engineer_id)) { //if the work has been assigned to them
                    element.isActive = false;
                    setArePending(true);
                    setListItems5(listItems2 => [...listItems2, element]);
                }
            }
            else if (element.IsAccepted === false && auth.user?.contractor_type) { //if not accepted but the user IS a ringmaster
                //check if the job has already been assinged to another engineer for acceptance
                if (element.EngineerId === auth.user?.engineer_id) { //if work is not accepted AND is currently allocated to the ringmaster
                    element.isActive = false;
                    setArePending(true);
                    setListItems5(listItems2 => [...listItems2, element]);
                }
                else { //if the work has not been accepted BUT it has been assigned then 
                    element.isActive = true;

                    //setListItems2(listItems2 => [...listItems2, element]);
                }

            }
            else {
                element.isActive = true;
                setAreAccepted(true);
                //setListItems2(listItems2 => [...listItems2, element]);

            }
            forceUpdate();
        }, [listItems, view]);

        setLoading(true);
        if (search !== "") {
            setNewEngineerList([]);
            engineerList.forEach(element => {
                let noOfJobs = listItems2.filter(item => item.EngineerName.indexOf(element.name) > -1).length;
                const elementIndex = engineerList.findIndex((each) => each.name === element.name)
                const updatedElement = { ...engineerList[elementIndex], assigned_jobs: noOfJobs };
                const newArray = engineerList.slice();
                newArray[elementIndex] = updatedElement;
                engineerList[elementIndex] = updatedElement;
                forceUpdate();
                //@ts-ignore


            });

        }
        else {
            setNewEngineerList([]);
            engineerList.forEach(element => {
                let noOfJobs = 1;
                const elementIndex = engineerList.findIndex((each) => each.name === element.name)
                const updatedElement = { ...engineerList[elementIndex], assigned_jobs: noOfJobs };
                const newArray = engineerList.slice();
                newArray[elementIndex] = updatedElement;
                engineerList[elementIndex] = updatedElement;

                //@ts-ignore
                forceUpdate();


            });
        }

        listItems2.sort(sortList);
        setLoading(false)

    }, [listItems]);

    useEffect(() => {
        refresh();
        setPostcodeList([])
    }, [view, history])

    return (
        <>
            {!ignore && refresh()}
            {!loading ?
                <Flex direction={'column'} h='calc(100vh - 198px)' w={"100%"}> {/* Bit of a bodge for now - have to manually set height based on hardcoded header height */}
                    <Box w='100%' bg='var(--Able-yellow)' borderBottomColor={"var(--Able-yellow)"} borderTopWidth={"0px"} borderBottomWidth={"0px"} shadow={"base"} display={"flex"} justifyContent={"left"} justifySelf={"left"} position={"absolute"} top={150}>
                        <Button onClick={() => {
                            setView(1);
                            Navigate("/dashboard/" + "1" + "/" + ("engineer") +  "");
                        }
                        } borderRadius={0} marginLeft={"0px"} as={Button} bgColor={view === 1 ? "var(--Able-blue)" : "var(--Able-yellow)"} textColor={view === 1 ? "white" : "var(--Able-blue)"} _hover={{ bgColor: "var(--Able-blue)", color: "white" }} _active={{ bg: "var(--Able-blue)", textColor: "white" }} isActive={view === 1} size={{ base: "md", lg: "lg" }}>
                            <Text >
                                Active Work
                            </Text>
                        </Button>
                        <Button onClick={() => {
                            setView(2);
                            Navigate("/dashboard/" + "2" + "/" + ("engineer") +  "");
                        }} borderRadius={0} marginLeft={"0px"} as={Button} bgColor={view === 2 ? "var(--Able-blue)" : "var(--Able-yellow)"} textColor={view === 2 ? "white" : "var(--Able-blue)"} _hover={{ bgColor: "var(--Able-blue)", color: "white" }} _active={{ bg: "var(--Able-blue)", textColor: "white" }} isActive={view === 2} size={{ base: "md", lg: "lg" }}>
                            <Text >
                                Completed Work
                            </Text>
                        </Button>
                        <Button onClick={() =>{
                            setView(3);
                            Navigate("/dashboard/" + "3" + "/" + ("engineer") +  "");
                        }} borderRadius={0} marginLeft={"0px"} as={Button} bgColor={view === 3 ? "var(--Able-blue)" : "var(--Able-yellow)"} textColor={view === 3 ? "white" : "var(--Able-blue)"} _hover={{ bgColor: "var(--Able-blue)", color: "white" }} _active={{ bg: "var(--Able-blue)", textColor: "white" }} isActive={view === 3} size={{ base: "md", lg: "lg" }}>
                            <Text >
                                Whiteboard
                            </Text>
                        </Button>
                        {/* <Flex justifyContent={{ base: "space-between", lg: "right" }} marginTop={"2px"} background={view === 3 ? "whitesmoke" : "white"}>
                            <Flex>
                                <Text as={"i"} fontSize={{ base: "md", lg: "xl" }} marginRight={"10px"}>Toggle Completed Work</Text>
                                <Switch size={{ base: "md", lg: "lg" }} onChange={view() => setShowCompleted(!showCompleted)} variant={"boxy"} />
                            </Flex>
                            {view !== 2 &&
                                <Flex>
                                    <Text as={"i"} fontSize={{ base: "md", lg: "xl" }} marginRight={"10px"}>Toggle View</Text>
                                    <Switch size={{ base: "md", lg: "lg" }} onChange={() => setDefaultView(!defaultView)} variant={"boxy"} />
                                </Flex>
                            }
                        </Flex> */}
                    </Box>
                    {view !== 3 ?
                        <Flex className="animate__animated animate__fadeIn" direction={"column"}>

                            {view === 1 &&
                                <>
                                    <Flex direction={"column"} justifyContent={"left"} alignItems={"left"} marginTop={"1%"} marginLeft={"1%"} width={"95%"}>
                                        <Text as={"p"} fontSize={{ base: "3xl", lg: "xl", xl: "5xl" }} color={"var(--Able-blue)"} textAlign={"left"} width={"100%"}>Assignable Work</Text>
                                        <Text as={"i"} fontSize={{ base: "md", lg: "md", xl: "xl" }} textAlign={"left"} width={"100%"}> These jobs are currently awaiting assignment </Text>
                                    </Flex>
                                    <Flex direction={"column"} width={"100%"} >
                                        {listItems5.length === 0 &&
                                            <Flex direction={"row"} justifyContent={"left"} alignItems={"left"} marginTop={"1%"} marginRight={"0%"} width={{ base: "100%", lg: "95%" }} marginLeft={{ base: "0px", xl: "20px" }} flexWrap={"wrap"}>
                                                <Text as={"a"} textColor={"var(--Able-blue)"} fontSize={{ base: "ms", lg: "md", xl: "xl" }} marginLeft={{base: "-5px", lg: "20px", xl:"-5px"}}>You don't currently have any jobs awaiting assignment! {":("}</Text>
                                            </Flex>
                                        }
                                        <Flex direction={"row"} justifyContent={"left"} alignItems={"left"} marginTop={"1%"} marginRight={"2%"} width={{ base: "100%", lg: "100%" }} flexWrap={"wrap"}>
                                            {listItems5.map(element =>
                                                <>
                                                    <JobBox {...element} cb={refresh} refreshCb={handleSubmit} complete={false} returnURL={"/" + String(view) + "/" + grouping + "/" + search} view={view} />
                                                </>
                                            )}
                                        </Flex>
                                    </Flex>

                                </>

                            }
                            <>
                                <Flex direction={"column"} justifyContent={"left"} alignItems={"left"} marginTop={"1%"} marginLeft={"1%"} width={"90%"}>
                                    <Text as={"p"} fontSize={{ base: "3xl", lg: "2xl", xl: "5xl" }} color={"var(--Able-blue)"} textAlign={"left"} width={"100%"}>{view === 2 ? "Your Completed Work" : "Your Active Jobs"}</Text>
                                    {view === 2 ?
                                        <Text as={"i"} fontSize={{ base: "md", lg: "md", xl: "xl" }} textAlign={"left"} width={"100%"}>{"These jobs have already been completed "}</Text>
                                        :
                                        <Text as={"i"} fontSize={{ base: "md", lg: "md", xl: "xl" }} textAlign={"left"} width={"100%"}> {auth.user?.contractor_type === 1 ? "These jobs are currently waiting for the assigned engineer to complete" : "These jobs are currently waiting to be completed"}</Text>
                                    }
                                </Flex>
                                <Flex direction={"row"} width={"100%"} marginRight={"-10px"}>
                                    <Flex direction={"column"} justifyContent={"left"} alignItems={"left"} marginTop={"1%"} marginRight={"0%"} width={"101%"}>
                                        {/* Search Bar */}
                                        <Flex direction={"row"}>

                                            <FormControl onSubmit={handleSubmit} marginTop={{ base: "0px", lg: "-10px" }} marginLeft={{ base: "0px", lg: "20px" }} marginBottom={"5px"} width={"100%"} >
                                                <InputGroup size={{ base: "sm", xl: "md" }} justifyContent={{ base: "space-around", lg: "left" }}>
                                                    <InputLeftAddon marginTop={{ base: "5px", lg: "0px", xl: "0px" }} visibility={{ base: "hidden", lg: "visible" }} >
                                                        <FaSearch size={"20px"} color="var(--Able-grey)" />
                                                    </InputLeftAddon>
                                                    <Input display={{ base: "none", lg: "inherit" }} size={{ base: "sm", lg: "sm", xl: "md" }} marginLeft={"0px"} placeholder="Enter a Job Reference or Postcode" width={{ base: "320px", lg: "25%" }} onChange={handleSearchChange} value={search} onSubmit={handleSubmit} onKeyDown={handleKeyDown} autoComplete={"new-text"} marginTop={{ base: "5px", lg: "0px" }} />

                                                    <Flex flexDirection={{ base: "column", lg: "row" }} marginLeft={{ base: "10px", lg: "0px" }} display={{ base: "none", lg: "flex" }}>
                                                        <Text marginLeft={{ base: "1px", lg: "100px" }} fontSize={{ base: "xs", lg: "sm", xl: "xl" }} marginTop={{ base: "-20px", lg: "5px", xl: "8px" }} marginRight={{ base: "1px", lg: "10px" }} display={"flex"}>Group By</Text>
                                                        <Select width={{ base: "120px", lg: "200px" }} value={grouping} size={{ base: "xs", lg: "sm", xl: "xl" }} onChange={
                                                            (event: any) => {
                                                                setGrouping(event.target.value)
                                                            }}>
                                                            <option value={'engineer'}>Engineer</option>
                                                            <option value={'postcode'}>Post Code</option>
                                                            <option value={'date'}>Date</option>
                                                            {view !== 2 &&
                                                                <>
                                                                    <option value={'accepted'}>Accepted</option>
                                                                    <option value={'onorder'}>Jobs on Order</option>
                                                                </>
                                                            }
                                                        </Select>
                                                    </Flex>
                                                    {view === 2 &&
                                                        <Flex flexDirection={{ base: "column", lg: "row" }} marginLeft={{ base: "10px", lg: "0px" }} display={{ base: "none", lg: "flex" }}>
                                                            <Text marginLeft={{ base: "1px", lg: "100px" }} fontSize={{ base: "xs", lg: "sm", xl: "xl" }} marginTop={{ base: "-20px", lg: "5px", xl: "5px" }} marginRight={{ base: "1px", lg: "-px", xl: "0px" }} display={"flex"} minW={"200px"}>Get Jobs from the Last: </Text>
                                                            <Select value={history} size={{ base: "xs", lg: "sm", xl: "xl" }} onChange={
                                                                (event: any) => {
                                                                    setHistory(event.target.value)
                                                                    forceUpdate();
                                                                }}>
                                                                <option value={'7'}>7 Days</option>
                                                                <option value={'14'}>14 Days</option>
                                                                <option value={'21'}>21 Days</option>
                                                                <option value={'28'}>28 Days</option>
                                                            </Select>
                                                        </Flex>
                                                    }
                                                    <Flex display={{ base: "revert", lg: "none" }}>
                                                        <MobileFilter search={search} changeSearch={setSearch} grouping={grouping} changeGrouping={setGrouping} history={history} changeHistory={setHistory}
                                                            handleSubmit={handleSubmitWithParameter} isComplete={true} />
                                                    </Flex>
                                                </InputGroup>

                                            </FormControl>

                                        </Flex>
                                        {/* @ts-ignore */}
                                        <Text display={!listItems.length > 0 ? "flex":"none"} fontSize={{base: "xl", lg: "md", xl: "md"}} textColor={"var(--Able-blue)"} marginLeft={"20px"} marginTop={"5px"}>Your search criteria returned no results</Text>
                                        {grouping === "engineer" &&
                                            <>
                                                {engineerList.map(each =>
                                                    <>
                                                        {(each.assigned_jobs > 0 && engineerList.length > 1) && auth.user?.contractor_type ?

                                                            <Accordion allowToggle={true}  >
                                                                <AccordionItem>
                                                                    <AccordionButton>
                                                                        <Text fontSize={{ base: "xl", lg: "xl", xl: "3xl" }} color={"var(--Able-blue)"} textAlign={"left"} width={"100%"}>{each.name}</Text>
                                                                        <AccordionIcon />
                                                                    </AccordionButton>
                                                                    <AccordionPanel>
                                                                        <Flex direction={"column"} justifyContent={"center"} flexWrap={"wrap"}>
                                                                            <Table style={{ borderCollapse: "separate", borderSpacing: "0 1em" }} size={"sm"}>
                                                                                <Thead>
                                                                                    <JobBoxHeading isComplete={(view === 2)} />
                                                                                </Thead>
                                                                                {/* <Tbody marginLeft={"-10px"} marginRight={"-10px"} w={"95%"} borderRadius={"2xl"}> */}
                                                                                <Tbody>
                                                                                    {listItems2.map(element =>
                                                                                        <>
                                                                                            {element.EngineerId === each.id &&
                                                                                                <JobBox {...element} cb={refresh} refreshCb={handleSubmit} complete={view === 2} returnURL={"/" + String(view) + "/" + grouping + "/" + search} view={view} />
                                                                                            }
                                                                                        </>
                                                                                    )}
                                                                                </Tbody>
                                                                            </Table>
                                                                        </Flex>
                                                                    </AccordionPanel>
                                                                </AccordionItem>
                                                            </Accordion>
                                                            :
                                                            <Flex direction={"column"} justifyContent={"center"} flexWrap={"wrap"}>
                                                                {each.assigned_jobs > 0 && each.id === auth.user?.engineer_id &&
                                                                    <>
                                                                    
                                                                        <Table style={{ borderCollapse: "separate", borderSpacing: "0 1em" }}>
                                                                            <Thead>
                                                                                <JobBoxHeading isComplete={(view === 2)} />
                                                                            </Thead>
                                                                            <Tbody bg={"var(--Able-blue)"} marginLeft={"-10px"} marginRight={"-10px"} w={"95%"}>
                                                                                {listItems2.map(element =>
                                                                                    <>
                                                                                        <>
                                                                                            <JobBox {...element} cb={refresh} refreshCb={handleSubmit} complete={(view === 2)} returnURL={"/" + String(view) + "/" + grouping + "/" + search} view={view} />
                                                                                        </>

                                                                                    </>
                                                                                )}
                                                                            </Tbody>
                                                                        </Table>
                                                                    </>
                                                                }
                                                            </Flex>
                                                        }
                                                    </>
                                                )}
                                            </>
                                        }
                                        {grouping === "postcode" &&
                                            <>

                                                {postcodeList.map(each =>
                                                    <>
                                                        <Accordion allowToggle={true}  display={listItems.length > 0 ? "flex":"none"}>
                                                            <AccordionItem>
                                                                <AccordionButton>
                                                                    <Text fontSize={{ base: "xl", lg: "xl", xl: "3xl" }} color={"var(--Able-blue)"} textAlign={"left"} width={"100%"}>{each}</Text>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                                <AccordionPanel>
                                                                    <Flex direction={"column"} justifyContent={"center"} flexWrap={"wrap"}>
                                                                        <Table style={{ borderCollapse: "separate", borderSpacing: "0 1em" }}>
                                                                            <Thead>
                                                                                <JobBoxHeading isComplete={(view === 2)} />
                                                                            </Thead>
                                                                            <Tbody bg={"var(--Able-blue)"} marginLeft={"-10px"} marginRight={"-10px"} w={"95%"}>
                                                                                {listItems2.map(element =>
                                                                                    <>
                                                                                        {getPostCode(element.PostCode) === each &&
                                                                                            <JobBox {...element} cb={refresh} refreshCb={handleSubmit} complete={(view === 2)} returnURL={"/" + String(view) + "/" + grouping + "/" + search} view={view} />
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                            </Tbody>
                                                                        </Table>
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>

                                                    </>
                                                )}
                                            </>
                                        }
                                        {grouping === "accepted" &&
                                            <>

                                                {acceptedList.map(each =>
                                                    <>
                                                        <Accordion allowToggle={true}  display={listItems.length > 0 ? "flex":"none"}>
                                                            <AccordionItem>
                                                                <AccordionButton>
                                                                    <Text fontSize={{ base: "xl", lg: "xl", xl: "3xl" }} color={"var(--Able-blue)"} textAlign={"left"} width={"100%"}>{each}</Text>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                                <AccordionPanel>
                                                                    <Flex direction={"column"} justifyContent={"center"} flexWrap={"wrap"}>
                                                                        {each === "Accepted" && areAccepted &&
                                                                            <>
                                                                                <Table style={{ borderCollapse: "separate", borderSpacing: "0 1em" }}>
                                                                                    <Thead>
                                                                                        <JobBoxHeading isComplete={(view === 2)} />
                                                                                    </Thead>
                                                                                    <Tbody bg={"var(--Able-blue)"} marginLeft={"-10px"} marginRight={"-10px"} w={"95%"} >{listItems2.map(element =>
                                                                                        <>
                                                                                            {element.IsAccepted === true && each === "Accepted" &&
                                                                                                <JobBox {...element} cb={refresh} refreshCb={handleSubmit} complete={(view === 2)} returnURL={"/" + String(view) + "/" + grouping + "/" + search} view={view} />
                                                                                            }
                                                                                        </>
                                                                                    )}</Tbody>
                                                                                </Table>
                                                                            </>
                                                                        }
                                                                        {each === "Pending" && arePending &&
                                                                            <><Table>
                                                                                <Thead>
                                                                                    <JobBoxHeading isComplete={(view === 2)} />
                                                                                </Thead>
                                                                                <Tbody bg={"var(--Able-blue)"} marginLeft={"-10px"} marginRight={"-10px"} w={"95%"}>{listItems2.map(element =>
                                                                                    <>
                                                                                        {element.IsAccepted === false && each === "Pending" &&
                                                                                            <JobBox {...element} cb={refresh} refreshCb={handleSubmit} complete={(view === 2)} returnURL={"/" + String(view) + "/" + grouping + "/" + search} view={view} />
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                                </Tbody>
                                                                            </Table>
                                                                            </>
                                                                        }

                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>

                                                    </>
                                                )}
                                            </>
                                        }
                                        {grouping === 'onorder' &&
                                            <>

                                                {isOnOrderList.map(each =>
                                                    <>
                                                        <Accordion allowToggle={true} display={listItems.length > 0 ? "inital":"none"}>
                                                            <AccordionItem>
                                                                <AccordionButton>
                                                                    <Text fontSize={{ base: "xl", lg: "xl", xl: "3xl" }} color={"var(--Able-blue)"} textAlign={"left"} width={"100%"}>{each}</Text>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                                <AccordionPanel>
                                                                    <Flex direction={"column"} justifyContent={"center"} flexWrap={"wrap"}>
                                                                        {each === "Is on Order" &&
                                                                            <>
                                                                                <Table style={{ borderCollapse: "separate", borderSpacing: "0 1em" }}>
                                                                                    <Thead>
                                                                                        {quantityOnOrder === 1 &&
                                                                                            <JobBoxHeading isComplete={showCompleted} />
                                                                                        }
                                                                                    </Thead>
                                                                                    <Tbody bg={"var(--Able-blue)"} marginLeft={"-10px"} marginRight={"-10px"} w={"95%"}>
                                                                                        {listItems2.map(element =>
                                                                                            <>
                                                                                                {element.IsOnOrder === true && each === "Is on Order" &&
                                                                                                    <JobBox {...element} cb={refresh} refreshCb={handleSubmit} complete={(view === 2)} returnURL={"/" + String(view) + "/" + grouping + "/" + search} view={view} />
                                                                                                }
                                                                                            </>
                                                                                        )}
                                                                                    </Tbody>
                                                                                </Table>
                                                                            </>
                                                                        }
                                                                        {each === "Not on Order" &&
                                                                            <>
                                                                                <Table>
                                                                                    <Thead>
                                                                                        <JobBoxHeading isComplete={(view === 2)} />
                                                                                    </Thead>
                                                                                    <Tbody bg={"var(--Able-blue)"} marginLeft={"-10px"} marginRight={"-10px"} w={"95%"}>
                                                                                        {listItems2.map(element =>
                                                                                            <>
                                                                                                {element.IsOnOrder === false && each === "Not on Order" &&
                                                                                                    <JobBox {...element} cb={refresh} refreshCb={handleSubmit} complete={(view === 2)} returnURL={"/" + String(view) + "/" + grouping + "/" + search} view={view} />
                                                                                                }
                                                                                            </>
                                                                                        )}
                                                                                    </Tbody>
                                                                                </Table>
                                                                            </>
                                                                        }
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>

                                                    </>
                                                )}

                                            </>
                                        }
                                        {grouping === "date" &&
                                            <>
                                                {dateList.map(each =>
                                                    <>
                                                        <Accordion allowToggle={true} display={listItems2.filter((x) => x.AppointmentDate.split("").slice(8, 10).join('') + "/" + x.AppointmentDate.split("").slice(5, 7).join('') + "/" + x.AppointmentDate.split("").slice(0, 4).join('') === each).length === 0 ? "none": "initial"}>
                                                            <AccordionItem>
                                                                <AccordionButton>
                                                                    <Text fontSize={{ base: "xl", lg: "xl", xl: "3xl" }} color={"var(--Able-blue)"} textAlign={"left"} width={"100%"}>{each}</Text>
                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                                <AccordionPanel>
                                                                    <Flex direction={"column"} justifyContent={"center"} flexWrap={"wrap"}>
                                                                        <Table style={{ borderCollapse: "separate", borderSpacing: "0 1em" }}>
                                                                            <Thead>
                                                                                <JobBoxHeading isComplete={(view === 2)} />
                                                                            </Thead>
                                                                            <Tbody bg={"var(--Able-blue)"} marginLeft={"-10px"} marginRight={"-10px"} w={"95%"}>
                                                                                {listItems2.map(element =>
                                                                                    <>
                                                                                        {(element.AppointmentDate).split("").slice(8, 10).join('') + "/" + (element.AppointmentDate).split("").slice(5, 7).join('') + "/" + (element.AppointmentDate).split("").slice(0, 4).join('')
                                                                                            === each &&
                                                                                            <JobBox {...element} cb={refresh} refreshCb={handleSubmit} complete={(view === 2)} returnURL={"/" + String(view) + "/" + grouping + "/" + search} view={view} />
                                                                                        }
                                                                                    </>
                                                                                )}
                                                                            </Tbody>
                                                                        </Table>
                                                                    </Flex>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>

                                                    </>
                                                )}
                                            </>
                                        }

                                    </Flex>
                                </Flex>
                            </>
                        </Flex >
                        :
                        <>

                            <ScaleFade initialScale={0.1} in={view === 3}>
                                <WhiteboardView />

                            </ScaleFade>

                        </>
                    }
                </Flex >
                :
                <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                    <Image srcSet={loadingGif} alt="loading..." boxSize={'400px'} objectFit={"cover"} display={view === 3 ? "none" : "block"} />
                </Flex>
            }
        </>
    )
}
