import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,

} from "@chakra-ui/react"
import { Select, Button, Input, Flex, InputGroup, InputLeftElement, Text, useToast} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import "../../App.scss"
import { MdRemoveCircleOutline } from "react-icons/md";
import { MdCheckCircle } from "react-icons/md";
import { useAuth } from "../../context/AuthContext";
import { IAddress } from "../../interfaces/service/IAddress";
import { IContractor } from "../../interfaces/service/IContractor";
import { AllocationStyle } from "../../interfaces/service/IContractor";
import {GrNote} from "react-icons/gr"
import { useWebHelper } from "../../hooks/UseWebHelper";


interface IWorkResult {
    AddressLine: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    disciplineName: string,
    disciplineAccent: string,
     
}
interface IEngineer {
    address: IAddress
    created: string,
    default_contact_method: number,
    email_address: string,
    id: string,
    is_enabled: boolean,
    land_line_number: string | null,
    mobile_number_1: string,
    mobile_number_2: string | null,
    name: string,
    push_enabled: boolean,
    push_platform: number,
    speed_dial: number,
    version: string
}
interface IReason {
    id: string,
    name: string,
    description: string | null,
}

export const Refusal = (props: IWorkResult) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [engineerList, setEngineerList] = useState<IEngineer[]>();
    const [defaultEngineer, setDefaultEngineer] = useState<IContractor>();
    const [selectedEngineer, setSelectedEngineer] = useState<string>();
    const [selectedReason , setSelectedReason ] = useState<string>();
    const [reasonsList, setReasonsList ] = useState<IReason[]>([]);
    const [notes, setNotes] = useState<string>("");


    const toast = useToast();
    //tets


    const handleSelectedReasonChange = (event: any) => {
        setSelectedReason(event.target.value);
    }


    const auth = useAuth();
    const webHelpers = useWebHelper();

    const engineer_id = auth.user?.engineer_id;

    const handleSubmit = async () => {
        console.log(selectedReason);
        let data = {
            appointment_id : props.AppointmentId,
            reason_id : selectedReason,
            engineer_id: auth?.user?.engineer_id,
            notes: notes,
        }
        return await webHelpers.PutAsync<any, any>(
            
            "/api/sales/work/appointment/refused","sales",data
        ).then((res) => {
            console.log("refused api response = ", res);
            props.cb();
        })

        //engineerId is tbd

    }
    const getRefusalReasons = async () => {
        return await webHelpers.GetAsync<any>(
            "/api/app/engineer/refusalreason/all", "sales"
        ).then( (res) => {
            console.log("refusal reasons : ", res);
            setReasonsList(res);
            setSelectedReason("Select a reason for refusal")
            // setSelectedReason(res[0].id)
        }
        )
    }

    const getEngineers = async () => {
        if (auth.user?.contractor_type === AllocationStyle.Ringmaster) {
            return await webHelpers.GetAsync<IEngineer[]>(
                "/api/service/contracting/engineer/forcontractor?id=" + auth.user?.contractor_id, "sales"
            ).then((res) => {
                if (res !== null) {
                    setEngineerList(res);
                }
            })
        }
    }
    const getRingmaster = async () => {
        return await webHelpers.GetAsync<IContractor>(
            "/api/service/contracting/contractor/get?id=" + auth.user?.contractor_id, "sales"
        ).then((res) => {
            if (res !== null && res !== undefined) {
                setDefaultEngineer(res);
                setSelectedEngineer(res.default_engineer_id);
            }
        })
    }

    const setAccept = async () => {
        let request = {
            appointment_id: props.AppointmentId,
            engineer_id: engineer_id,

        }
        return await webHelpers.PutAsync<string, object>(
            "/api/sales/work/appointment/accept", "sales", request
        ).then((res) => {
            props.cb()
            props.cb()
            props.cb()
        }


        )
    }
    const allocateJob = async () => {
        let request2 = {
            appointment: props.AppointmentId,
            engineer: selectedEngineer,
        }
        return await webHelpers.PostAsync<string, object>(
            "/api/sales/work/appointment/allocate","sales", request2
        )
    }
    const setAcceptRingmaster = async () => {
        let request = {
            appointment_id: props.AppointmentId,
            engineer_id: engineer_id,
        }
        console.log(request);
        return await webHelpers.PutAsync<string, object>(
            "/api/sales/work/appointment/accept", "sales", request
        ).then((res) => {
            console.log("appointment/accept result : ", res);
            allocateJob().then((response) =>{
                console.log("allocateJob() response : ", response);
                props.cb()
                props.cb()
                props.cb()
            })
            console.log("finished allocating job")
        })
    }
    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
        />
    )

    const OverlayTwo = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        />
    )
    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    
    const handleNotesChange = (e: React.FormEvent<HTMLInputElement>) => {
        setNotes(e.currentTarget.value);

    }

    return (

        <>
            <>
                <Button onClick={() => {
                    setOverlay(<OverlayOne />)
                    onOpen()
                    getEngineers()
                    getRingmaster()
                    getRefusalReasons()
                }
                } variant={"solid"} colorScheme={"red"} marginTop={{base: "-15px", lg: "-30px"}} width={"100px"} size={{base: "sm", lg: "xs", xl:"md"}} marginLeft={"10px"} marginBottom={{base: "-10px", lg: "0px"}} 
                
                >Decline</Button>

                <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered>
                    <ModalOverlay />
                    <ModalContent >
                        <ModalHeader>Decline Job</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                                <>
                                    <Text>Select a reason for refusal</Text>
                                    <Flex justifyContent={"center"} direction={"column"} marginTop={"5%"} marginBottom={"4%"}>
                                        <Select value={selectedReason} onChange={handleSelectedReasonChange} placeholder="Select a reason for refusal" isInvalid={selectedReason === ""}>
                                                <>
                                                    {reasonsList.map(each =>
                                                        <option value={each.id}>
                                                            {(each.name)}
                                                        </option>
                                                    )}
                                                </>
                                            
                                        </Select>
                                        <InputGroup>
                                                    <InputLeftElement marginTop={"1%"} >
                                                        <GrNote size={"15px"} color={"red"}/>
                                                    </InputLeftElement>
                                                    <Input size={"md"} placeholder="Additional Notes" width={"100%"} marginTop={"1%"} onChange={handleNotesChange} value={notes} onSubmit={handleSubmit}/>
                                                </InputGroup>
                                    </Flex>
                                </>
                            
                            <Text>Are you sure you want to decline this job? </Text>
                            <Flex justifyContent={"center"} direction={"row"} marginTop={"5%"} marginBottom={"4%"}>
                                <Button onClick={handleSubmit} isDisabled={!(notes !== "" && selectedReason !== "") } marginRight={"4%"} leftIcon={<MdCheckCircle />} colorScheme="whatsapp" >Yes</Button>
                                <Button leftIcon={<MdRemoveCircleOutline />} colorScheme="red" mr={3} onClick={onClose}>
                                    No
                                </Button>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>

        </>
    )

}