import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { Fonts } from "./fonts"
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { switchTheme } from './components/customStyles/switch';
import { createBreakpoints } from "@chakra-ui/theme-tools"

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};
// @ts-ignore
const breakpoints = createBreakpoints({
  base: "0px",
    sm: '0px',
    md: '900px',
    lg: '960px',
    xl: '1200px',
    '2xl': '1536px',
})

export const theme = extendTheme({
  breakpoints,
  fonts: {
    heading: "Open Sans",
    body: "Open Sans2",
  },
  components: {
    Switch: switchTheme,
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label": {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top"
            }
          }
        }
      }
    }
  },
  // breakpoints:{
  //   base: "0px",
  //   sm: '320px',
  //   lg: '897px',
  //   lg: '960px',
  //   xl: '1200px',
  //   '2xl': '1536px',
  // },
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <>
    <ChakraProvider theme={theme}>
      <Fonts />
      <App />
    </ChakraProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
