import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Grid,
    GridItem,
    filter,

} from "@chakra-ui/react"
import { Box } from "@chakra-ui/react";
import Scheduler, { Resource, Scrolling, View, AppointmentDragging, Editing } from "devextreme-react/scheduler";
import Draggable from 'devextreme-react/draggable';
import dxScrollView from "devextreme/ui/scroll_view";
import { Button, Flex, Image, Text, useToast, Badge } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import "../../App.scss"
import { useAuth } from "../../context/AuthContext";
import { IAddress } from "../../interfaces/service/IAddress";
import { IContractor } from "../../interfaces/service/IContractor";
import { AllocationStyle } from "../../interfaces/service/IContractor";
import { AllocationStatus, IDisciplineResult } from "../../interfaces/service/Interfaces";
import "./styles.scss"
import loadingIcon from "../../assets/loadingIcon.gif"
import { useCache } from "../../context/CacheContext";
import { useWebHelper } from "../../hooks/UseWebHelper";
import { Appointment } from "./appointment";


interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,
    refreshCb: Function,
    complete: boolean
}

interface IEngineer {
    address: IAddress
    created: string,
    default_contact_method: number,
    email_address: string,
    id: string,
    is_enabled: boolean,
    land_line_number: string | null,
    mobile_number_1: string,
    mobile_number_2: string | null,
    name: string,
    push_enabled: boolean,
    push_platform: number,
    speed_dial: number,
    version: string
}
export interface IWhiteboard {
    text: string,
    ownerId: string,
    startDate: Date,
    endDate: Date,
    discipline: string,
    description: string,
}
interface IEngineerGrouping {
    text: string,
    id: string,
}
interface IDisciplineGrouping {
    text: string,
    id: string,
    color: string,
}
interface INewAppointment {
    text: string
}
interface IDateArray {
    id: string,
    startDate: Date,
    endDate: Date,
}
interface IBackupTest {
    id: string,
    jobNumber: string,
}
export const JobBoxModal = (props: IWorkResult) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [engineerList, setEngineerList] = useState<IEngineer[]>();
    const [defaultEngineer, setDefaultEngineer] = useState<IContractor>();
    const [selectedEngineer, setSelectedEngineer] = useState<string>("");
    const [whiteBoardData, setWhiteBoardData] = useState<any[]>();
    const [filteredWhiteboard, setFilteredWhiteboard] = useState<IWhiteboard[]>([]);
    const [filteredWhiteboard2, setFilteredWhiteboard2] = useState<IWhiteboard[]>([]);
    const [backupTest, setBackupTest] = useState<IBackupTest[]>([]);
    const [priorityData, setPriorityData] = useState<IEngineerGrouping[]>([])
    const [colorData, setColorData] = useState<IDisciplineGrouping[]>([])
    const [newAppointment, setNewAppointment] = useState<INewAppointment[]>([{ text: props.JobNumber }])
    const toast = useToast();
    const test = React.useRef("string");
    const [loading, setLoading] = useState<boolean>(true);

    const handleSelectedEngineerChange = (event: any) => {
        setSelectedEngineer(event.target.value);
    }
    const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);
    const [saveDates, setSaveDates] = useState<IDateArray[]>([])
    const [idsChanged, setIdsChanged] = useState<string[]>([]);
    const [savingInProgress, setSavingInProgress] = useState<boolean>(false);

    const cache = useCache();
    const webHelpers = useWebHelper();
    const auth = useAuth();
    const schedulerRef = React.useRef(null);

    const engineer_id = auth.user?.engineer_id;

    useEffect(() => {
        console.log("backup test = ", backupTest)
    }, [backupTest]);

    const updatedAppointment = (e: any) => {
        // console.log(filteredWhiteboard);
        // console.log(filteredWhiteboard2);
        console.log(filteredWhiteboard2.filter((each) => each.text === e.appointmentData.text)[0].ownerId[0])
        setSaveSuccessful(false);
        let testList = filteredWhiteboard.filter((each) => each.text !== e.appointmentData.text)
        let testElement = filteredWhiteboard.filter((each) => each.text === e.appointmentData.text)[0]
        console.log("testElement = ", testElement.ownerId);
        // console.log(filteredWhiteboard.filter((each) => each.text === e.appointmentData.text)[0]);
        // console.log(testElement)
        let testDateObject = saveDates.filter((each) => each.id === e.appointmentData.text)[0]
        // console.log(testDateObject)
        testElement.startDate = testDateObject.startDate;
        testElement.endDate = testDateObject.endDate;
        if (e.appointmentData.text === props.JobNumber) {
            testElement.ownerId = e.appointmentData.ownerId[0];
            setSelectedEngineer(testElement.ownerId);
        }
        else {
            testElement.ownerId = backupTest.filter((each) => each.jobNumber === e.appointmentData.text)[0].id
            console.log("testElement = ", testElement.ownerId);
        }

        testList.push(testElement)

        setFilteredWhiteboard(testList);
        //schedulerRef.current.insatance.getDataSource().reload();
        //schedulerRef.current.instance.endUpdate();


        // setFilteredWhiteboard(testList => [...filteredWhiteboard, newItem])

    }
    useEffect(() => {
        console.log("filtered whiteboard = ", filteredWhiteboard)
    }, [filteredWhiteboard])


    const handleSubmit = (id: string) => {
        if (auth.user?.contractor_type === AllocationStyle.FreeAgent) {
            setAccept(id)


        }
        else if (auth.user?.contractor_type === AllocationStyle.Ringmaster) {
            setAcceptRingmaster(id)
        }
        //engineerId is tbd

    }
    const getEngineers = async () => {
        setPriorityData([])
        setColorData([]);
        cache.lookUpCacheItem<IDisciplineResult[]>("disciplines")?.forEach(element => {
            console.log("element = ", element)
            console.log("props = ", props)
            let data = {
                text: element.name,
                id: element.id,
                color: props.JobNumber === element.id ? "backgroundGrey" : element.accent
            }
            setColorData(colorData => [...colorData, data])
        });
        console.log(auth.user);
        if (auth.user?.contractor_type === AllocationStyle.Ringmaster) {

            return await webHelpers.GetAsync<IEngineer[]>(
                "/api/service/contracting/engineer/forcontractor?id=" + auth.user?.contractor_id, "sales"
            ).then((res) => {
                if (res !== null) {
                    setEngineerList(res);
                    res.reverse();
                    //@ts-ignore
                    res.forEach(element => {
                        let data: IEngineerGrouping = {
                            text: "default",
                            id: "default",
                        }
                        console.log("data for scheduler = ", data)
                        //@ts-ignore
                        data.text = element.name;
                        data.id = element.id;
                        //data.color = "yellow"
                        setPriorityData(priorityData => [...priorityData, data])
                    });
                }
            })
        }
    }
    const getRingmaster = async () => {
        return await webHelpers.GetAsync<IContractor>(
            "/api/service/contracting/contractor/get?id=" + auth.user?.contractor_id, "sales"
        ).then((res) => {
            if (res !== null && res !== undefined) {
                setDefaultEngineer(res);
                if (auth.user?.contractor_type === AllocationStyle.FreeAgent) {
                    setSelectedEngineer(auth.user?.engineer_id)
                }
                else {
                    setSelectedEngineer(res.default_engineer_id);
                }

            }
        })
    }

    const setAccept = async (id: string) => {
        let request = {
            appointment_id: props.AppointmentId,
            engineer_id: id,

        }
        return await webHelpers.PutAsync<string, object>(
            "/api/sales/work/appointment/accept", "sales", request
        ).then((res) => {
            console.log("non-ringmaster accept response = ", res);
            props.cb()
            props.cb()
            props.cb()
        }
        )
    }
    const allocateJob = async (id: string) => {
        let request2 = {
            appointment: props.AppointmentId,
            engineer: id,
        }
        return await webHelpers.PostAsync<string, object>(
            "/api/sales/work/appointment/allocate", "sales", request2
        )
    }
    const setAcceptRingmaster = async (id: string) => {
        let request = {
            appointment_id: props.AppointmentId,
            engineer_id: id,
        }
        return await webHelpers.PutAsync<string, object>(
            "/api/sales/work/appointment/accept", "sales", request
        ).then((res) => {
            if (id !== defaultEngineer?.default_engineer_id) {
                allocateJob(id).then((response) => {

                })
            }
            props.cb()
            props.cb()
            props.cb()

        })
    }
    const getWhiteboard = async () => {
        return await webHelpers.GetAsync<any>(
            "/api/sales/work/whiteboard/get?date=" + props.AppointmentDate + "&contractor=" + auth.user?.contractor_id, "sales"
        ).then((res) => {
            setWhiteBoardData(res);
            setFilteredWhiteboard([]);
            //@ts-ignore
            res.forEach(element => {
                //@ts-ignore
                element.job_sheets.forEach(every => {
                    let data: IWhiteboard = {
                        text: every.job_number,
                        ownerId: element.engineer.id,
                        startDate: new Date(every.appointment.start_date),
                        endDate: new Date(every.appointment.end_date),
                        discipline: every.discipline_id,
                        description: "Value of Job: " + every.value_nett.toLocaleString("en-GB", { style: "currency", currency: "GBP" }) + "\n VAT (20%) : " + (every.value_nett * 0.2).toLocaleString("en-GB", { style: "currency", currency: "GBP" }) + "\n Total Value : " + every.value_gross.toLocaleString("en-GB", { style: "currency", currency: "GBP" }),
                    };
                    let saveDateData: IDateArray = {
                        id: every.job_number,
                        startDate: new Date(every.appointment.start_date),
                        endDate: new Date(every.appointment.end_date),
                    }
                    setSaveDates(saveDates => [...saveDates, saveDateData])
                    let backupData: IBackupTest = {
                        id: element.engineer.id,
                        jobNumber: every.job_number,
                    }
                    //@ts-ignore
                    //@ts-ignore
                    //@ts-ignore

                    //filteredWhiteboard.push(data);
                    //@ts-ignore
                    setFilteredWhiteboard(filteredWhiteboard => [...filteredWhiteboard, data]);
                    setFilteredWhiteboard2(filteredWhiteboard => [...filteredWhiteboard, data]);
                    setBackupTest(backupTest => [...backupTest, backupData])
                });
                // data.text = element.job_sheets[0].job_number;
                // data.ownerId = element.engineer.id;
                // data.startDate = new Date(element.job_sheets[0].appointment.start_date);
                // data.endDate = new Date(element.job_sheets[0].appointment.end_date);
                //@ts-ignore
                // setFilteredWhiteboard(filteredWhiteboard => [filteredWhiteboard, data]);


            });
            setLoading(false);

        })
    }
    useEffect(() => {
        console.log("filteredWhiteboard2 = ", filteredWhiteboard2)
    }, [filteredWhiteboard2])
    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
        />
    )
    const handleCellClick = (e: any) => {
        if (e.cellData.groups !== null && e.cellData.groups !== undefined) {
            setSelectedEngineer(e.cellData.groups.ownerId)
        }
        else {
            console.log("error field 'ownerId' is null ");
        }
    }
    const appointment = (e: any) => {
        let form = e.form;
        let mainGroup = form.itemOption("mainGroup").items;
        let description = mainGroup.find((i: any) => { return i.dataField === "description" });

        e.popup.option("showTitle", true)
        e.popup.option("title", "Details for Job " + e.appointmentData.text)
    }

    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    const groups = ['ownerId'];
    const draggingGroupName = 'appointmentsGroup';


    return (

        <>
            <>
                <Button onClick={() => {
                    setOverlay(<OverlayOne />)
                    onOpen()
                    getEngineers()
                    getRingmaster()
                    getWhiteboard()
                    setLoading(true)
                }
                } variant={"solid"} colorScheme="whatsapp" marginTop={{ base: "-15px", lg: "-30px" }} width={"100px"} size={{ base: "sm", lg: "xs", xl: "md" }} marginBottom={{ base: "-20px", lg: "0px" }}>Accept</Button>

                <Modal isOpen={isOpen} onClose={onClose} size={"full"}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                        >
                            <Flex direction={"row"}>

                                <Flex direction={"column"} >
                                    <Flex direction={"row"} justifyContent={"space-between"} width={"100%"}>
                                        <Text color={"white"} fontSize={"4xl"}>Accept Job</Text>
                                    </Flex>
                                    {auth.user?.contractor_type === AllocationStyle.Ringmaster ?
                                        <>
                                            <Text as={"i"} fontSize={"sm"}>Simply drag and drop the Job to the engineer you would like to allocate to</Text>
                                        </>

                                        :
                                        <Text as={"i"} fontSize={"sm"} width={"90%"}>Here is a preview of all work for the day of the job you are accepting </Text>
                                    }
                                </Flex>

                                {/* @ts-ignore */}
                                {/* <JobBox {...props}/> */}

                            </Flex>
                            <Flex width={"200px"} height={"30px"}>
                                <Text color={"white"} fontSize={"xl"} textAlign={"right"}>Currently Allocating: </Text>
                            </Flex>
                            <Flex direction={"column"} marginLeft={"0px"}>
                                <Flex direction={"row"}>
                                    <Flex width={"300px"}>
                                        <Text as={"b"} color={"white"}>{props.JobNumber}</Text>
                                        {/* @ts-ignore */}
                                        <Badge marginLeft={"3%"} color={props.disciplineAccent} marginTop={"2%"} borderColor={"var(--Able-grey)"} borderLeftColor={props.disciplineAccent} borderWidth={"3px"}
                                            height="24px" marginBottom={"0%"} backgroundColor={"var(--Able-grey)"} > {props.disciplineName} </Badge>
                                    </Flex>
                                </Flex>
                                <Flex direction={"column"} justifyContent={"left"}>
                                    <Text color={"white"} textAlign={"left"} fontSize={"sm"}>
                                        {(props.AppointmentDate).split("").slice(8, 10).join('')}
                                        /
                                        {(props.AppointmentDate).split("").slice(5, 7).join('')}
                                        /

                                        {(props.AppointmentDate).split("").slice(0, 4).join('')}
                                    </Text>
                                    <Text color={"white"} textAlign={"left"} fontSize={"sm"}>
                                        {(props.StartTime).split("").slice(11, 16).join("")} -
                                        {(props.EndTime).split("").slice(11, 16).join("")}</Text>
                                    <Flex direction={"row"}>

                                        <Flex justifyContent={"right"}>

                                        </Flex>

                                    </Flex>

                                </Flex>
                            </Flex>

                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody

                        >
                            {/* {auth.user?.contractor_type === AllocationStyle.Ringmaster && */}
                            {true &&
                                <>
                                    {!loading ?
                                        <>
                                            <React.Fragment>
                                                <Scheduler
                                                    timeZone="Europe/London"
                                                    dataSource={filteredWhiteboard}
                                                    //@ts-ignore
                                                    currentView={"Whiteboard View"}
                                                    defaultCurrentDate={new Date(props.AppointmentDate)}

                                                    groups={groups}
                                                    cellDuration={60}
                                                    firstDayOfWeek={0}
                                                    startDayHour={0}
                                                    endDayHour={24}
                                                    crossScrollingEnabled={true}
                                                    onAppointmentUpdated={(e: any) => updatedAppointment(e)}
                                                    appointmentComponent={(appointmentProps) => Appointment({ data: appointmentProps.data, index: appointmentProps.index, allocationJobNumber: props.JobNumber })}
                                                // onCellClick={handleCellClick}
                                                // onAppointmentFormOpening={appointment}

                                                >

                                                    <View
                                                        name="Whiteboard View"
                                                        type="timelineDay"
                                                        cellDuration={120}
                                                        intervalCount={1}
                                                        scrolling={{ mode: "standard" }}
                                                        maxAppointmentsPerCell={"unlimited"}
                                                    />
                                                    <Resource
                                                        fieldExpr={"ownerId"}
                                                        allowMultiple={true}
                                                        dataSource={priorityData}
                                                        label={"Currently Assigned Engineer"}
                                                    />
                                                    <Resource
                                                        fieldExpr={"discipline"}
                                                        allowMultiple={true}
                                                        dataSource={colorData}
                                                        label={"Type of Work"}
                                                        useColorAsDefault={true}
                                                    />
                                                    <Editing
                                                        allowResizing={false}
                                                        allowDragging={true}
                                                        allowAdding={false}

                                                    />

                                                </Scheduler>
                                            </React.Fragment>
                                            <Flex justifyContent={"center"} marginTop={"10px"}>
                                                <Button onClick={() => handleSubmit(selectedEngineer)} colorScheme="blue" alignSelf={"center"}>Assign Job</Button>
                                            </Flex>
                                            <Text color={"white"}>.</Text>
                                        </>
                                        :
                                        <Flex direction={"row"} justifyContent={"center"} width={"100%"} bgColor={"white"}>
                                            <Image srcSet={loadingIcon} alt="loading..." boxSize={'500px'} objectFit={"cover"} />
                                        </Flex>
                                    }
                                </>
                            }
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>

        </>
    )

}