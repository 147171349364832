import {
    Tr,
    Th,
} from "@chakra-ui/react";
import { Tooltip, Flex, Text} from '@chakra-ui/react';
import { ImInfo } from "react-icons/im";

interface headingProps {
    isComplete: boolean
}
export const JobBoxHeading = (props: headingProps) => {

    return (
        <Tr>
            <Th padding={{base: "10px", lg: "auto"}} fontSize={{base: "2xs", lg: "md"}}>Job Reference</Th>
            <Th padding={{base: "10px", lg: "auto"}} fontSize={{base: "xs", lg: "md"}} display={{base: "none", lg: "revert"}}>Discipline</Th>


            <Th padding={{base: "10px", lg: "auto"}} fontSize={{base: "xs", lg: "md"}} display={{base: "none", lg: "revert"}}>Postcode</Th>


            <Th padding={{base: "10px", lg: "auto"}} fontSize={{base: "xs", lg: "md"}} textAlign={{base: "center", lg: "left"}}>Date</Th>
            <Th padding={{base: "10px", lg: "auto"}} fontSize={{base: "xs", lg: "md"}} display={{base: "none", lg: "revert"}} >Timeslot</Th>
            <Th padding={{base: "10px", lg: "auto"}} fontSize={{base: "2xs", lg: "md"}} visibility={props.isComplete ? "hidden" : "revert"}>Accepted</Th>
            <Th padding={{base: "10px", lg: "auto"}} visibility={props.isComplete ? "hidden" : "revert"}>
                <Tooltip placement="right" label={
                    "Jobs are listed from highest to lowest priority per day, use the arrows to adjust the order in which you plan to complete each job."
                }>
                    <Flex direction={"row"}>
                        <Flex marginTop={{base: "10px", lg: "1px"}} marginRight={"10px"}>
                    <ImInfo color="var(--Able-grey)" />
                    </Flex>
                    <Text  fontSize={{base: "2xs", lg: "md"}}>Change Priority</Text>
                    {/* <Button marginTop={{ base: "5px", lg: "-8px" }}  variant={"ghost"} marginRight={{ base: "0%", lg: "0%" }} size={{ base: "xs", lg: "sm" }} width={{ base: "30px", lg: "50px" }}> */}
                        
                    {/* </Button> */}
                    </Flex>
                    
                </Tooltip>
            </Th>

        </Tr>
    )
}