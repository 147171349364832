import { useAuth } from "../context/AuthContext";

export const useWebHelper = () => {
    let auth = useAuth(); // The only issue 

    async function GetAsync<T>(url: string, endpoint: string): Promise<T | null> {
        try {
            let data = {
                url: url,
                endpoint: endpoint,
                method: "GET",
                data: {}
            }
        
            var requestOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }
    
            const response: Response = await (fetch("/api/proxy", requestOptions).catch(handleError));

            if (response.status === 403) {
                auth.signOut();
                return null;
            }
    
            if (response.ok) {
                const data:T = await response.json();
                return data;
            } else {
                return Promise.reject(response);
            }
    
        } catch (error) {
            console.error(error);
            return Promise.reject();
        }
    }
    
    async function PostAsync<T, U>(url: string, endpoint: string, body: U): Promise<T | null> {
        try {
            let data = {
                url: url,
                endpoint: endpoint,
                method: "POST",
                data: body
            }
        
            var requestOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }
    
            const response: Response = await (fetch("/api/proxy", requestOptions).catch(handleError));

            if (response.status === 403) {
                auth.signOut();
                return null;
            }
    
            if (response.ok) {
                const data:T = await response.json();
                return data;
            } else {
                return Promise.reject(response);
            }
    
        } catch (error) {
            console.error(error);
            return Promise.reject();
        }
    }
    
    async function PutAsync<T, U>(url: string, endpoint: string, body: U): Promise<T | null> {
        try {
            let data = {
                url: url,
                endpoint: endpoint,
                method: "PUT",
                data: body
            }
        
            var requestOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }
    
            const response: Response = await (fetch("/api/proxy", requestOptions).catch(handleError));

            if (response.status === 403) {
                auth.signOut();
                return null;
            }
    
            if (response.ok) {
                const data:T = await response.json();
                return data;
            } else {
                return Promise.reject(response);
            }
    
        } catch (error) {
            console.error(error);
            return Promise.reject();
        }
    }
    
    async function DeleteAsync<T, U>(url: string, endpoint: string, body: U): Promise<T | null> {
        try {
            let data = {
                url: url,
                endpoint: endpoint,
                method: "DELETE",
                data: body
            }
        
            var requestOptions = {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }
    
            const response: Response = await (fetch("/api/proxy", requestOptions).catch(handleError));

            if (response.status === 403) {
                auth.signOut();
                return null;
            }
    
            if (response.ok) {
                const data:T = await response.json();
                return data;
            } else {
                return Promise.reject(response);
            }
    
        } catch (error) {
            console.error(error);
            return Promise.reject();
        }
    }
    
    var handleError = function (err: any) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Error occurred client side fetching resource'
        }));
    }

    return {GetAsync, PostAsync, PutAsync, DeleteAsync};
}


