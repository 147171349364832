import { Box } from "@chakra-ui/react";
import { Flex, Link, Image, Text, Button } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import "../../App.scss"
import { useAuth } from '../../context/AuthContext';


interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number
}



export const PageContainer = (props: IPageContainerProps) => {
    const authContext = useAuth();
    let navigate = useNavigate();



    const handleLogOut = async () => {
        let response = await authContext.signOut();
        console.log(response);
    }
    const goToDashboard = (params: string) => {
        navigate("/dashboard" + params);
    }


    return (
        <>
            <Box w='100%' bgGradient='linear-gradient(90deg, rgba(6,87,156,1) 49%, rgba(245,250,254,1) 86%, rgba(255,255,255,1) 95%);' borderBottomColor={"var(--Able-yellow)"} shadow={"base"}>
                <Flex direction={"row"} h='150px' width={"100%"}>

                    <Flex direction={"column"} marginLeft={{ base: "10px", lg: "50px" }} width={"100%"} marginTop={"10px"}>
                        <Text color={"white"} fontSize={{ base: "xl", md: "4xl", lg: "5xl", xl: "6xl" }} textAlign={"left"}>Viabl Engineer Portal</Text>
                        <Text as={"i"} color={"white"} fontSize={{ base: "md", lg: "2xl" }} textAlign={"left"} marginTop={{ base: "30px", md: "30px", lg: "0px" }}>Welcome, {authContext.user?.engineer_name} {" - "}

                            <Link color={"var(--Able-yellow)"} onClick={handleLogOut}>
                                Sign Out
                            </Link>
                        </Text>
                    </Flex>
                    <Flex justifyContent={"right"} width={"100%"}>
                        <Image src={viablLogo} align={"right"} display={"flex"} height={"full"} alignSelf={"right"}></Image>
                    </Flex>
                </Flex>
                <Flex bg={"var(--Able-yellow)"}>
                    {props.pageTitle === "Jobsheet" ?
                        <Button height={"48px"} onClick={() => goToDashboard("/1/engineer")} borderRadius={0} marginLeft={"0px"} as={Button} bgColor={"var(--Able-yellow)"} textColor={"var(--Able-blue)"} _hover={{ bgColor: "var(--Able-blue)", color: "white" }} _active={{ bg: "var(--Able-blue)", textColor: "white" }} size={{ base: "md", lg: "lg" }}>
                            Dashboard
                        </Button>
                        :
                        <>
                            <Button height={"48px"} onClick={() => navigate("/dashboard/" + "1" + "/" + ("engineer") +  "")} borderRadius={0} marginLeft={"0px"} as={Button} bgColor={"var(--Able-yellow)"} textColor={"var(--Able-blue)"} _hover={{ bgColor: "var(--Able-blue)", color: "white" }} _active={{ bg: "var(--Able-blue)", textColor: "white" }} size={{ base: "md", lg: "lg" }}>
                                Active Work
                            </Button>
                            <Button height={"48px"} onClick={() => navigate("/dashboard/" + "2" + "/" + ("engineer") +  "")} borderRadius={0} marginLeft={"0px"} as={Button} bgColor={"var(--Able-yellow)"} textColor={"var(--Able-blue)"} _hover={{ bgColor: "var(--Able-blue)", color: "white" }} _active={{ bg: "var(--Able-blue)", textColor: "white" }} size={{ base: "md", lg: "lg" }}>
                                Completed Work
                            </Button>
                            <Button height={"48px"} onClick={() => navigate("/dashboard/" + "3" + "/" + ("engineer") +  "")} borderRadius={0} marginLeft={"0px"} as={Button} bgColor={"var(--Able-yellow)"} textColor={"var(--Able-blue)"} _hover={{ bgColor: "var(--Able-blue)", color: "white" }} _active={{ bg: "var(--Able-blue)", textColor: "white" }} size={{ base: "md", lg: "lg" }}>
                                Whiteboard
                            </Button>
                        </>
                    }
                </Flex>
            </Box >
            {props.children}
        </>
    )
}