import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,

} from "@chakra-ui/react"
import { Button, Flex, Text, useToast} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import "../../App.scss"
import { useAuth } from "../../context/AuthContext";
import { IAddress } from "../../interfaces/service/IAddress";
import { IContractor } from "../../interfaces/service/IContractor";
import { IWipLineWithCb } from "../../interfaces/service/Interfaces";
import { AllocationStyle } from "../../interfaces/service/IContractor";
import { AllocationStatus, IDisciplineResult, IWipLine } from "../../interfaces/service/Interfaces";
import { useCache } from "../../context/CacheContext";
import { useWebHelper } from "../../hooks/UseWebHelper";
import { MdOutlineDone } from "react-icons/md";
import 'react-day-picker/dist/style.css';
import moment from "moment"

interface IWorkResult {
    AddressLine: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    disciplineName: string,
    disciplineAccent: string,

}
interface IEngineer {
    address: IAddress
    created: string,
    default_contact_method: number,
    email_address: string,
    id: string,
    is_enabled: boolean,
    land_line_number: string | null,
    mobile_number_1: string,
    mobile_number_2: string | null,
    name: string,
    push_enabled: boolean,
    push_platform: number,
    speed_dial: number,
    version: string
}
interface IWhiteboard {
    text: string,
    ownerId: string,
    startDate: Date,
    endDate: Date,
    discipline: string,
    description: string,
}
interface IEngineerGrouping {
    text: string,
    id: string,
}
interface IDisciplineGrouping {
    text: string,
    id: string,
    color: string,
}
export const ReadyModal = (props: IWipLineWithCb) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [engineerList, setEngineerList] = useState<IEngineer[]>();
    const [defaultEngineer, setDefaultEngineer] = useState<IContractor>();
    const [selectedEngineer, setSelectedEngineer] = useState<string>("");
    const [whiteBoardData, setWhiteBoardData] = useState<any[]>();
    const [filteredWhiteboard, setFilteredWhiteboard] = useState<IWhiteboard[]>([]);
    const [priorityData, setPriorityData] = useState<IEngineerGrouping[]>([])
    const [colorData, setColorData] = useState<IDisciplineGrouping[]>([])
    const [count, setCount] = useState<number>(1);
    const toast = useToast();
    const test = React.useRef("string");
    const [loading, setLoading] = useState<boolean>(true);
    const [selected, setSelected] = useState<Date>();
    const handleSelectedEngineerChange = (event: any) => {
        setSelectedEngineer(event.target.value);
    }
    const cache = useCache();
    const webHelpers = useWebHelper();
    const auth = useAuth();

    const engineer_id = auth.user?.engineer_id;


    const getEngineers = async () => {
        setPriorityData([])
        setColorData([]);
        cache.lookUpCacheItem<IDisciplineResult[]>("disciplines")?.forEach(element => {
            let data = {
                text: element.name,
                id: element.id,
                color: element.accent,
            }
            setColorData(colorData => [...colorData, data])
        });

        if (auth.user?.contractor_type === AllocationStyle.Ringmaster) {
            return await webHelpers.GetAsync<IEngineer[]>(
                "/api/service/contracting/engineer/forcontractor?id=" + auth.user?.contractor_id, "sales"
            ).then((res) => {
                if (res !== null) {
                    setEngineerList(res);
                    //@ts-ignore
                    res.forEach(element => {
                        let data: IEngineerGrouping = {
                            text: "default",
                            id: "default",
                        }
                        //@ts-ignore
                        data.text = element.name;
                        data.id = element.id;
                        //data.color = "yellow"
                        setPriorityData(priorityData => [...priorityData, data])
                    });
                }
            })
        }
    }
    const getRingmaster = async () => {
        return await webHelpers.GetAsync<IContractor>(
            "/api/service/contracting/contractor/get?id=" + auth.user?.contractor_id, "sales"
        ).then((res) => {
            if (res !== null && res !== undefined) {
                setDefaultEngineer(res);
                setSelectedEngineer(res.default_engineer_id);
            }
        })
    }

    const placeToOrder = async () => {
        let data = {
            id: props.id,
            engineerId: props.engineer_id,
            expected_date: String(moment(selected, "YYYY-MM-DD").format("YYYY-MM-DD")),
            type: "engineer",
        }
        console.log("/api/sales/work/wip/order", "sales", data);
        return await webHelpers.PostAsync<any, any>(
            "/api/sales/work/wip/order/", "sales", data
        ).then((res) => {
            console.log("place to order response", res);
            onClose();
        })
    }
    const materialsReady = async () => {
        let data ={
            id: props.id,
        }
        return await webHelpers.PostAsync<any, any>(
            "/api/sales/work/wip/ready", "sales", data
        ).then((res) => {
            onClose();
            props.cb();
        })
    }
    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
        />
    )

    const OverlayTwo = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        />
    )
    const handleCellClick = (e: any) => {
        if (e.cellData.groups !== null && e.cellData.groups !== undefined) {
            setSelectedEngineer(e.cellData.groups.ownerId)
        }
        else {
            console.log("error field 'ownerId' is null ");
        }
    }
    const appointment = (e: any) => {
        console.log(e.appointmentData);
        let form = e.form;
        let mainGroup = form.itemOption("mainGroup").items;
        console.log("mg", mainGroup);
        let description = mainGroup.find((i: any) => { return i.dataField === "description" });
        console.log("description = ", description);

        e.popup.option("showTitle", true)
        e.popup.option("title", "Details for Job " + e.appointmentData.text)
        console.log(form.itemOption("mainGroup").items);
    }

    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    const groups = ['ownerId'];

    return (

        <>
            <>
            {props.status === 50 &&
                <Button 
                    marginLeft={{base: "0px", lg: "10px", xl:"15px"}}
                    marginBottom={{base: "10px", xl:"0px"}}       
                    onClick={() => {
                    setOverlay(<OverlayOne />)
                    onOpen()
                    getEngineers()
                    getRingmaster()
                    setLoading(true)
                    
                }
                } variant={"solid"} colorScheme={"blue"} bg={"var(--Able-blue)"} textColor={"white"} marginTop={{base: "10px", lg: "0px",  xl:"16px"}} leftIcon={<MdOutlineDone color="var(--Able-yellow)"/>} width={{base: "100%", xl: "160px"}}>Materials Ready</Button>
            }
                <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                        >
                            <Flex direction={"row"}>

                                <Flex direction={"column"} justifyContent={"center"}>
                                    <Flex direction={"row"} justifyContent={"space-between"} width={"100%"}>
                                        <Text color={"white"} fontSize={"4xl"}>Update Delivery Status</Text>
                                    </Flex>
                                </Flex>
                            </Flex>


                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody

                        >
                            <Flex justifyContent={"center"} direction={"column"}>
                                    <Text textAlign={"center"}>Are you sure you want to take the job off order?</Text>
                                <br/>
                                <Flex direction="row" justifyContent={"center"} marginBottom={"1%"} >
                                    <Button width={"100px"} onClick={materialsReady} colorScheme={"green"} margin={"10px"}>Yes</Button>
                                    <Button width={"100px"} onClick={onClose} colorScheme="red"  margin={"10px"}>No</Button>
                                </Flex>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>

        </>
    )

}