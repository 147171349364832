import {useDisclosure,
} from "@chakra-ui/react";
import { Button, Select, FormControl, Input, Flex, InputGroup, InputLeftElement, Text} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import "../../../App.scss"
import { useAuth } from "../../../context/AuthContext";
import e from "express";
import { useCache } from "../../../context/CacheContext";
import { FaSearch } from "react-icons/fa"
import { useWebHelper } from "../../../hooks/UseWebHelper";
import { BiSlider } from "react-icons/bi"
import 'react-day-picker/dist/style.css';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,

} from "@chakra-ui/react"

import "../../../App.scss"
import "../../../components/scheduler/styles.scss"
import 'react-day-picker/dist/style.css';
interface filterProps {
    search: string,
    changeSearch: Function,
    grouping: string,
    changeGrouping: Function,
    history: string,
    changeHistory: Function
    handleSubmit: Function,
    isComplete: boolean
}
export const MobileFilter = (props: filterProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [search, setSearch] = useState<string>(props.search);
    const [grouping, setGrouping] = useState<string>(props.grouping);
    const [history, setHistory] = useState<string>(props.history)
    const cache = useCache();
    const webHelpers = useWebHelper();
    const auth = useAuth();


    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(10px)'
        />
    )

    const handleKeyDown = (event: any) => {
        if (event.key === "Enter") {
            props.handleSubmit();
        }
    }
    const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);

    }
    const handleGroupingChange = (e: React.FormEvent<HTMLInputElement>) => {
        setGrouping(e.currentTarget.value);

    }

    const handleHistoryChange = (e: React.FormEvent<HTMLInputElement>) => {
        setHistory(e.currentTarget.value);

    }



    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    const groups = ['ownerId'];

    return (

        <>
            <>
                <Button
                    marginLeft={{ base: "10px", xl: "15px" }}
                    marginBottom={{ base: "10px", xl: "0px" }}
                    leftIcon={<BiSlider />}
                    onClick={() => {
                        setOverlay(<OverlayOne />)
                        onOpen();

                    }
                    } variant={"solid"} colorScheme={"blue"} bg={"var(--Able-blue)"} textColor={"white"} marginTop={{ base: "10px", xl: "0px" }} width={{ base: "100%", xl: "revert" }}>
                    Filter
                </Button>
                <Modal isOpen={isOpen} onClose={() => {onClose(); props.handleSubmit();}} isCentered size={"full"}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader
                        >
                            <Flex direction={"row"}>

                                <Flex direction={"column"} justifyContent={"center"}>
                                    <Flex direction={"row"} justifyContent={"space-between"} width={"100%"}>
                                        <Text color={"white"} fontSize={"4xl"}>Filter Results</Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </ModalHeader>
                        <ModalBody

                        >
                            <Flex justifyContent={"center"} direction={"column"}>
                                <FormControl onSubmit={() => props.handleSubmit} marginLeft={{ base: "0px", lg: "20px" }} marginBottom={"5px"} width={"100%"} >
                                    <InputGroup>
                                        <Flex direction={"column"} w={"100%"}>
                                            <InputLeftElement marginTop={{ base: "5px", lg: "0px" }} display={{base: "none", lg: "flex"}}>
                                                <FaSearch size={"20px"} color="var(--Able-grey)" />
                                            </InputLeftElement>
                                            <Input size={"md"} placeholder="Enter a Job Reference or Postcode" width={{ base: "100%", lg: "25%" }} onChange={handleSearchChange} value={search} onSubmit={() => props.handleSubmit} onKeyDown={handleKeyDown} autoComplete={"new-text"} marginTop={{ base: "5px", lg: "0px" }} />

                                            <Flex flexDirection={{ base: "row", lg: "row" }} marginLeft={{ base: "10px", lg: "0px" }} marginTop={{ base: "10px", lg: "0px" }} justifyContent={"space-between"}>
                                                <Text marginLeft={{ base: "1px", lg: "100px" }} size={{ base: "xs", lg: "xl" }} marginTop={{ base: "5px", lg: "8px" }} marginRight={{ base: "1px", lg: "10px" }} display={"flex"}>Group By</Text>
                                                <Select width={{ base: "120px", lg: "200px" }} value={grouping} onChange={
                                                    (event: any) => {
                                                        handleGroupingChange(event)
                                                        // forceUpdate();
                                                    }}>
                                                    <option value={'engineer'}>Engineer</option>
                                                    <option value={'postcode'}>Post Code</option>
                                                    {!props.isComplete &&
                                                        <>
                                                            <option value={'accepted'}>Accepted</option>
                                                            <option value={'onorder'}>Jobs on Order</option>
                                                        </>
                                                    }
                                                </Select>
                                            </Flex>
                                            {props.isComplete &&
                                                <Flex flexDirection={{ base: "row", lg: "row" }} marginLeft={{ base: "10px", lg: "0px" }} marginTop={{ base: "10px", lg: "0px" }} justifyContent={"space-between"}>
                                                    <Text marginLeft={{ base: "1px", lg: "100px" }} size={{ base: "xs", lg: "xl" }} marginTop={{ base: "5px", lg: "5px" }} marginRight={{ base: "1px", lg: "px" }} display={"flex"} minW={"200px"}>Get Jobs from the Last: </Text>
                                                    <Select value={history} onChange={
                                                        (event: any) => {
                                                           handleHistoryChange(event)
                                                            // forceUpdate();
                                                        }}>
                                                        <option value={'7'}>7 Days</option>
                                                        <option value={'14'}>14 Days</option>
                                                        <option value={'21'}>21 Days</option>
                                                        <option value={'28'}>28 Days</option>
                                                    </Select>
                                                </Flex>
                                            }
                                            <Button 
                                            type="submit"
                                            marginTop={"10px"}
                                            onClick={() => {
                                                
                                                onClose();
                                                props.changeGrouping(grouping);
                                                props.changeHistory(history);
                                                props.changeSearch(search);
                                                props.handleSubmit(search)
                                                
                                            }
                                            }>Apply</Button>
                                        </Flex>

                                    </InputGroup>


                                </FormControl>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </>

        </>
    )

}