import {
    ModalOverlay,
    useDisclosure,
    Grid,
    GridItem,
    Slide,
    Tooltip,

} from "@chakra-ui/react"
import { Box } from "@chakra-ui/react";
import Scheduler, { Resource, Scrolling, View, AppointmentDragging, Editing } from "devextreme-react/scheduler";
import { Fade, ScaleFade, Flex, Image, Text, useToast, Button as ChakraButton } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import "./styles.scss"
import { useAuth } from "../../context/AuthContext";
import { IAddress } from "../../interfaces/service/IAddress";
import { IContractor } from "../../interfaces/service/IContractor";
import { AllocationStyle } from "../../interfaces/service/IContractor";
import { AllocationStatus, IDisciplineResult } from "../../interfaces/service/Interfaces";
import "../jobBox/styles.scss"
import loadingIcon from "../../assets/loadingIcon.gif"
import { useCache } from "../../context/CacheContext";
import moment from "moment";
import { useWebHelper } from "../../hooks/UseWebHelper";
import { group } from "console";
import { StringMappingType } from "typescript";
import { Button } from "react-day-picker";
import { BiSave } from "react-icons/bi"
import { useNavigate } from "react-router";
import { AiFillWarning } from "react-icons/ai";
import { FiHelpCircle } from "react-icons/fi";

interface IEngineer {
    address: IAddress
    created: string,
    default_contact_method: number,
    email_address: string,
    id: string,
    is_enabled: boolean,
    land_line_number: string | null,
    mobile_number_1: string,
    mobile_number_2: string | null,
    name: string,
    push_enabled: boolean,
    push_platform: number,
    speed_dial: number,
    version: string
}
interface IWhiteboard {
    text: string,
    ownerId: string,
    startDate: Date,
    endDate: Date,
    discipline: string,
    description: string,
}
interface IEngineerGrouping {
    text: string,
    id: string,
}
interface IDisciplineGrouping {
    text: string,
    id: string,
    color: string,
}
interface IDateArray {
    id: string,
    startDate: Date,
    endDate: Date,
}
export const WhiteboardView = () => {
    const [engineerList, setEngineerList] = useState<IEngineer[]>();
    const [defaultEngineer, setDefaultEngineer] = useState<IContractor>();
    const [selectedEngineer, setSelectedEngineer] = useState<string>("");
    const [whiteBoardData, setWhiteBoardData] = useState<any[]>();
    const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);
    const [filteredWhiteboard, setFilteredWhiteboard] = useState<IWhiteboard[]>([]);
    const [priorityData, setPriorityData] = useState<IEngineerGrouping[]>([])
    const [colorData, setColorData] = useState<IDisciplineGrouping[]>([])
    const [saveDates, setSaveDates] = useState<IDateArray[]>([])
    const [idsChanged, setIdsChanged] = useState<string[]>([]);
    const [savingInProgress, setSavingInProgress] = useState<boolean>(false);

    const toast = useToast();
    const test = React.useRef("string");
    const [currentTime, setCurrentTime] = useState<any>(moment().format("YYYY-MM-DD"))
    const [loading, setLoading] = useState<boolean>(true);
    const handleSelectedEngineerChange = (event: any) => {
        setSelectedEngineer(event.target.value);
    }

    const cache = useCache();
    const webHelpers = useWebHelper();
    const auth = useAuth();
    const schedulerRef = React.useRef(null);
    const navigate = useNavigate();

    const engineer_id = auth.user?.engineer_id;

    // const handleSubmit = (id: string) => {
    //     if (auth.user?.contractor_type === AllocationStyle.FreeAgent) {
    //         setAccept(id)


    //     }
    //     else if (auth.user?.contractor_type === AllocationStyle.Ringmaster) {
    //         setAcceptRingmaster(id)
    //     }
    //     //engineerId is tbd

    //}
    const getEngineers = async () => {

        setColorData([]);
        cache.lookUpCacheItem<IDisciplineResult[]>("disciplines")?.forEach(element => {
            let data = {
                text: element.name,
                id: element.id,
                color: element.accent,
            }
            setColorData(colorData => [...colorData, data])
        });
        // setEngineerList([])
        if (auth.user?.contractor_type === AllocationStyle.Ringmaster) {
            return await webHelpers.GetAsync<IEngineer[]>(
                "/api/service/contracting/engineer/forcontractor?id=" + auth.user?.contractor_id, "sales"
            ).then((res) => {
                if (res !== null) {
                    setPriorityData([])
                    setEngineerList(res);
                    console.log("engineers ", res)
                    //@ts-ignore
                    res.forEach(element => {
                        let data: IEngineerGrouping = {
                            text: "default",
                            id: "default",
                        }
                        //@ts-ignore
                        data.text = element.name;
                        data.id = element.id;
                        //data.color = "yellow"
                        setPriorityData(priorityData => [...priorityData, data])

                    });
                }
                console.log("priorityData", priorityData)
            })

        }
    }
    const getRingmaster = async () => {
        return await webHelpers.GetAsync<IContractor>(
            "/api/service/contracting/contractor/get?id=" + auth.user?.contractor_id, "sales"
        ).then((res) => {
            if (res !== null && res !== undefined) {
                setDefaultEngineer(res);
                setSelectedEngineer(res.default_engineer_id);
            }
        })
    }

    useEffect(() => {
        console.log("engineer list has been changed ", engineerList);
    }, [engineerList]);
    // const setAccept = async (id: string) => {
    //     let request = {
    //         appointment_id: props.AppointmentId,
    //         engineer_id: id,

    //     }
    //     return await webHelpers.PutAsync<string, object>(
    //         "/api/sales/work/appointment/accept", "sales", request
    //     ).then((res) => {
    //         props.cb()
    //         props.cb()
    //         props.cb()
    //     }
    //     )
    // }
    // const allocateJob = async (id: string) => {
    //     let request2 = {
    //         appointment: props.AppointmentId,
    //         engineer: id,
    //     }
    //     return await webHelpers.PostAsync<string, object>(
    //         "/api/sales/work/appointment/allocate", "sales", request2
    //     )
    // }
    // const setAcceptRingmaster = async (id: string) => {
    //     let request = {
    //         appointment_id: props.AppointmentId,
    //         engineer_id: id,
    //     }
    //     return await webHelpers.PutAsync<string, object>(
    //         "/api/sales/work/appointment/accept", "sales", request
    //     ).then((res) => {
    //         allocateJob(id).then((response) => {
    //             props.cb()
    //             props.cb()
    //             props.cb()
    //         })
    //     })
    // }
    const getWhiteboard = async (date: any) => {
        return await webHelpers.GetAsync<any>(
            "/api/sales/work/whiteboard/get?date=" + date + "&contractor=" + auth.user?.contractor_id, "sales"
        ).then((res) => {
            setWhiteBoardData(res);
            console.log("res", res)
            setFilteredWhiteboard([]);
            setSaveDates([]);
            setIdsChanged([]);
            setSaveSuccessful(false);
            //@ts-ignore
            res.forEach(element => {
                //@ts-ignore
                element.job_sheets.forEach(every => {
                    console.log("every ", every)
                    let data: IWhiteboard = {
                        text: every.job_number,
                        ownerId: element.engineer.id,
                        startDate: new Date(every.appointment.start_date),
                        endDate: new Date(every.appointment.end_date),
                        discipline: every.discipline_id,
                        description: "Value of Job: " + every.value_nett.toLocaleString("en-GB", { style: "currency", currency: "GBP" }) + "\n VAT (20%) : " + (every.value_nett * 0.2).toLocaleString("en-GB", { style: "currency", currency: "GBP" }) + "\n Total Value : " + every.value_gross.toLocaleString("en-GB", { style: "currency", currency: "GBP" }),
                    };
                    let saveDateData: IDateArray = {
                        id: every.job_number,
                        startDate: new Date(every.appointment.start_date),
                        endDate: new Date(every.appointment.end_date),
                    }
                    setSaveDates(saveDates => [...saveDates, saveDateData])
                    //@ts-ignore
                    //@ts-ignore

                    //filteredWhiteboard.push(data);
                    //@ts-ignore
                    setFilteredWhiteboard(filteredWhiteboard => [...filteredWhiteboard, data]);
                });
                // data.text = element.job_sheets[0].job_number;
                // data.ownerId = element.engineer.id;
                // data.startDate = new Date(element.job_sheets[0].appointment.start_date);
                // data.endDate = new Date(element.job_sheets[0].appointment.end_date);
                //@ts-ignore
                // setFilteredWhiteboard(filteredWhiteboard => [filteredWhiteboard, data]);


            });
            setLoading(false);

        })
    }

    const OverlayOne = () => (
        <ModalOverlay
            bg='blackAlpha.300'
            backdropFilter='blur(0px)'
        />
    )

    const OverlayTwo = () => (
        <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
        />
    )
    const handleCellClick = (e: any) => {
        setSelectedEngineer(e.cellData.groups.ownerId)
    }
    const appointment = (e: any) => {
        console.log(e.appointmentData);
        let form = e.form;
        let mainGroup = form.itemOption("mainGroup").items;
        console.log("mg", mainGroup);
        let description = mainGroup.find((i: any) => { return i.dataField === "description" });
        console.log("description = ", description);

        e.popup.option("showTitle", true)
        e.popup.option("title", "Details for Job " + e.appointmentData.text)
        console.log(form.itemOption("mainGroup").items);
    }

    const [overlay, setOverlay] = React.useState(<OverlayOne />)
    const groups = ['ownerId'];

    const startFunction = () => {
        console.log("loading true")
        setLoading(true);
        console.log(currentTime)
        getEngineers()
        getRingmaster()
        getWhiteboard(currentTime).then((res) => {
            console.log("should set loading false now")
            setLoading(false);
        })
    }
    const handleDateChange = (date: string) => {

        setLoading(true);
        getWhiteboard(date).then(
            (res) => {
                setLoading(false)
            });

    }
    const updatedAppointment = (e: any) => {
        setSaveSuccessful(false);

        if (!(idsChanged.includes(e.appointmentData.text))) {
            setIdsChanged(idsChanged => [...idsChanged, e.appointmentData.text])
        }
        let testList = filteredWhiteboard.filter((each) => each.text !== e.appointmentData.text)
        let testElement = filteredWhiteboard.filter((each) => each.text === e.appointmentData.text)[0]
        let testDateObject = saveDates.filter((each) => each.id === e.appointmentData.text)[0]
        testElement.startDate = testDateObject.startDate;
        testElement.endDate = testDateObject.endDate;
        testElement.ownerId = e.appointmentData.ownerId[0];
        testList.push(testElement)
        setFilteredWhiteboard(testList);
        //schedulerRef.current.insatance.getDataSource().reload();
        //schedulerRef.current.instance.endUpdate();


        // setFilteredWhiteboard(testList => [...filteredWhiteboard, newItem])

    }
    const saveChanges = () => {
        setSavingInProgress(true);
        setSaveSuccessful(false);
        let saveSuccess = false;

        try {
            if (idsChanged.length === 0) {
                setSavingInProgress(false);
            }
            else {
                idsChanged.forEach((res) => {
                    console.log("/api/sales/work/allocation/get?id=" + res)
                    webHelpers.GetAsync<any>("/api/sales/work/allocation/jobsheet?id=" + res, "sales").then((res) => {
                        console.log("allocations = ", res)
                        let data = {
                            appointment: res[0].appointment_id,
                            engineer: filteredWhiteboard.filter((each) => each.text === res[0].job_number)[0].ownerId,
                        }
                        console.log(data)
                        webHelpers.PostAsync<any, any>("/api/sales/work/appointment/allocate", "sales", data).then((response) => {
                            console.log("response = ", response);
                            setSavingInProgress(false);
                            setSaveSuccessful(true)
                        })
                    })
                })
            }
        }
        catch {
            console.log("error occurred")
            setSavingInProgress(false);
        }



    }
    const openJobsheet = (e: any) => {
        console.log(e);
        navigate("/jobsheet/" + e.appointmentData.text + "/3")

    }
    useEffect(() => {
    }, [loading]);
    return (

        <>
            <>
                {/* {auth.user?.contractor_type === AllocationStyle.Ringmaster && */}
                {true &&
                    <>

                        <>
                            <React.Fragment>
                                <Fade in={!loading}  >
                                    <Scheduler ref={schedulerRef}
                                        timeZone="Europe/London"
                                        dataSource={filteredWhiteboard}
                                        //@ts-ignore
                                        currentView={"Whiteboard View"}
                                        defaultCurrentDate={new Date(currentTime)}
                                        groups={groups}
                                        cellDuration={60}
                                        firstDayOfWeek={0}
                                        startDayHour={0}
                                        endDayHour={24}
                                        crossScrollingEnabled={true}
                                        onContentReady={() => console.log(filteredWhiteboard)}
                                        onAppointmentFormOpening={appointment}
                                        onInitialized={startFunction}
                                        onAppointmentUpdated={(e: any) => updatedAppointment(e)}
                                        onAppointmentDblClick={openJobsheet}
                                        onAppointmentClick={function (e) {
                                            e.cancel=true;
                                        }}
                                        editing={
                                            {
                                                allowAdding: false,
                                                allowDeleting: false,
                                                allowDragging: true,
                                                allowResizing: false,
                                                allowTimeZoneEditing: false,
                                            }
                                        }

                                        onOptionChanged={(e: any) => {
                                            if (e.name === "currentDate") {
                                                setCurrentTime(moment(e.value, "YYYY-MM-DD").format("YYYY-MM-DD"));
                                                handleDateChange(moment(e.value, "YYYY-MM-DD").format("YYYY-MM-DD"))
                                            }
                                        }
                                        }
                                    >
                                        <Editing

                                        />
                                        <View
                                            name="Whiteboard View"
                                            type="timelineDay"
                                            cellDuration={120}
                                            intervalCount={1}
                                            maxAppointmentsPerCell={"unlimited"}

                                            scrolling={{ mode: "virtual" }}

                                        />
                                        <Resource
                                            fieldExpr={"ownerId"}
                                            allowMultiple={true}
                                            dataSource={priorityData}
                                            label={"Currently Assigned Engineer"}
                                        />
                                        <Resource
                                            fieldExpr={"discipline"}
                                            allowMultiple={true}
                                            dataSource={colorData}
                                            label={"Type of Work"}
                                            useColorAsDefault={true}
                                        />
                                        <Editing
                                            allowResizing={false}
                                            allowDragging={true}
                                            allowAdding={false}

                                        />
                                    </Scheduler>
                                    <Box height={"550px"} justifyContent={"center"} display={"flex"}>
                                        <Tooltip placement="left" label={
                                            "You can click and drag jobs around to reassign them to your engineers, or double click a job to open the full jobsheet view"
                                        }>
                                            <Flex direction={"row"} position={"absolute"} top={"217px"} zIndex={10} paddingTop={"0px"} right={"150px"} >
                                                <Flex display={{base: "none", lg: "flex"}} marginTop={{ base: "10px", lg: "1px" }} marginRight={"10px"}>
                                                    <FiHelpCircle color="var(--Able-grey)"/>
                                                </Flex>
                                            </Flex>

                                        </Tooltip>
                                        <Slide direction="bottom" in={idsChanged.length > 0 && !saveSuccessful}>
                                            <Box bg={"var(--Able-grey)"} justifyContent={"center"} display={"flex"} flexDirection={"row"}>

                                                <Text as={"b"} color={"white"} width={"100%"} display={"flex"} flexDirection={"row"} textAlign={"center"} fontSize={"20px"} marginLeft={"43%"}>
                                                    <Text marginTop={"4px"} marginRight={"10px"} fontSize={"25px"} justifySelf={"center"} ><AiFillWarning /></Text>
                                                    Unsaved Changes!
                                                </Text>
                                            </Box>

                                        </Slide>

                                        {idsChanged.length > 0 &&
                                            <>

                                                {
                                                    saveSuccessful ?
                                                        <ChakraButton position={"absolute"} top={"150px"} height={"48px"} paddingTop={"0px"} right={0} onClick={() => saveChanges()}
                                                            borderRadius={0} marginLeft={"0px"} bgColor={"var(--Able-yellow)"} textColor={"var(--Able-blue)"} _hover={{ bgColor: "var(--Able-blue)", color: "white" }} _active={{ bg: "var(--Able-blue)", textColor: "white" }} size={{ base: "md", lg: "lg" }}>Save Successful</ChakraButton>
                                                        :
                                                        <ChakraButton position={"absolute"} top={"150px"} height={"48px"} paddingTop={"0px"} right={0} onClick={() => saveChanges()}
                                                            borderRadius={0} marginLeft={"0px"} bgColor={"var(--Able-yellow)"} textColor={"var(--Able-blue)"} _hover={{ bgColor: "var(--Able-blue)", color: "white" }} _active={{ bg: "var(--Able-blue)", textColor: "white" }} size={{ base: "md", lg: "lg" }} isLoading={savingInProgress}>Save Changes</ChakraButton>

                                                }
                                            </>
                                        }
                                    </Box>
                                </Fade>
                            </React.Fragment>
                            {loading &&
                                <Flex direction={"row"} justifyContent={"center"} width={"100%"} bgColor={"white"} position={"absolute"} top={200} left={1}>
                                    <ScaleFade in={loading} initialScale={1}>
                                        <Box height={"100%"} justifyContent={"center"} paddingBottom={"0%"}>
                                            <Image srcSet={loadingIcon} alt="loading..." boxSize={'400px'} objectFit={"cover"} align={"center"} marginTop={"50%"} />
                                        </Box>
                                        {/* <Flex direction={"row"} justifyContent={"center"} width={"100%"} marginTop={"11%"} bgColor={"white"}>
                                            <Image srcSet={loadingIcon} alt="loading..." boxSize={'400px'} objectFit={"cover"} />
                                        </Flex> */}
                                    </ScaleFade>
                                </Flex>
                            }
                            <React.Fragment>
                                <Flex>

                                </Flex>
                                {/* <Grid position={"absolute"} marginBottom={"20px"} top={304} left={0} bg={"rgba(0,0,0,0);"} width={"100%"} templateColumns={"1"} gap={0} pointerEvents={"none"}>
                                    {engineerList?.map(each =>
                                        <GridItem as={each.id === selectedEngineer ? "div" : "button"} w={each.id === selectedEngineer ? "100%" : "100px"} h="75px" bg={(each.id === selectedEngineer && !loading) ? "linear-gradient(90deg, rgba(148,190,255,0.2805497198879552) 50%, rgba(255,255,255,0) 99%)" : "rgba(0,0,0,0)"}
                                            onClick={() => { setSelectedEngineer(each.id) }}
                                            pointerEvents={"none"} />
                                    )}
                                </Grid> */}
                            </React.Fragment>
                            <Text color={"white"}>.</Text>
                        </>

                    </>
                }
            </>

        </>
    )

}