import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { SignIn } from '../pages/login';
import { Dashboard } from '../pages/dashboard';
import { JobsheetPage } from '../pages/jobsheet';
import { RequireAuth } from '../context/RequireAuth';
import { AuthProvider } from '../context/AuthContext';
import NotFoundPage from '../pages/errors/NotFound';
import { CacheProvider } from '../context/CacheContext';

export function PageRoutes() {
    return (
        <AuthProvider>
            <CacheProvider>
            <Routes>
                <Route path="/" element={
                    <Navigate to="/signin"/>
                } />

                <Route path="/signin" element={<SignIn/>} />

                <Route path="/dashboard/:selectedView?/:filter?/:search?" element={
                    <RequireAuth>
                        <Dashboard/>
                    </RequireAuth> }
                />
                    
                <Route path="/jobsheet/:jobNumber/:selectedView?/:filter?/:search?" element={
                    <RequireAuth>
                        <JobsheetPage/>
                    </RequireAuth> }
                />

                <Route path="/*" element={<NotFoundPage />} />
            
            </Routes>
            </CacheProvider>
        </AuthProvider>
    )
}