import { Box } from "@chakra-ui/react";
import {
    Button, Input, Flex, Image, useToast, Heading, InputGroup, InputLeftElement, InputLeftAddon, Alert, AlertIcon, AlertTitle, AlertDescription, Text, Badge, Spinner, Checkbox, keyframes, Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import viablLogo from "../../assets/viablLogo.png"
import { AiOutlineUser } from "react-icons/ai";
import { BiLockOpenAlt } from "react-icons/bi";
import "../../App.scss"
import { Layer } from 'devextreme-react/vector-map';
import { Title } from "devextreme-react/bar-gauge";
import { StringMappingType } from "typescript";
import { JobBoxModal } from "./modal";
import { JobsheetModal } from "./jobsheetModal";
import { useAuth } from "../../context/AuthContext";
import { SlArrowUp, SlArrowDown } from "react-icons/sl"
import { Refusal } from "./refusal";
import useForceUpdate from "use-force-update";
import { useWebHelper } from "../../hooks/UseWebHelper";
import { ImCross } from "react-icons/im";

interface IPageContainerProps {
    pageTitle: string,
    children: React.ReactNode;
}
interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,
    refreshCb: Function,
    complete: boolean
    returnURL: string,
    view: number,
}
interface discipline {
    id: string,
    accent: string,
    name: string,

}

export const JobBox = (props: IWorkResult) => {
    const auth = useAuth();
    const webHelpers = useWebHelper();
    const [disciplineObject, setDiscipline] = useState<discipline>();
    const [ignore, setIgnore] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [defaultEngineer, setDefaultEngineer] = useState<string>("");
    const [newIndex, setNewIndex] = useState<number>(props.RouteIndex);

    const forceUpdate = useForceUpdate();
    const toast = useToast();

    const spin = keyframes`
         from {transform: rotate(0deg);}
         to {transform: rotate(360deg)}
        `;

    const refresh = () => {
        console.log(props)
        // @ts-ignore
        setDefaultEngineer(auth.user?.engineer_name);


        setIgnore(true);
        setLoading(false)



    }
    const update = () => {
        webHelpers.GetAsync<any | null>(
            "/api/service/contracting/contractor/outstandingwork/" + auth.user?.contractor_id + "?filter=" + props.JobNumber, "sales"
        ).then((res) => {
            forceUpdate();
        })
    }
    const handleIndexChange = (mode: string) => {
        let newData = props.RouteIndex
        if (mode === "decrease") {
            newData += 1;
        }
        else if (mode === "increase") {
            newData -= 1;
        }
        let data = {
            appointment_id: props.AppointmentId,
            engineer_id: props.EngineerId,
            index: newData,
        }
        console.log("data = ", data);
        webHelpers.PostAsync<any, any>(
            "/api/sales/work/appointment/index", "sales", data
        ).then((res) => {
            console.log("index change response = ", res);
            props.refreshCb();
            return (
                toast({
                    title: "Index Adjusted",
                    description: "The priority of Job " + props.JobNumber + " has been successfully " + mode + "d",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                })
            )
        })
    }
    return (
        <>
            {!ignore && refresh()}
            {props.isActive ?
                // <Box alignContent={"center"} display={"block"} backgroundColor={"var(--Able-blue)"} margin={"0.25%"} paddingLeft={{ base: "-1px", lg: "0.5%" }} paddingRight={"0.5%"} width={{ base: "99%", lg: "99%" }} borderRadius={"lg"} height={"40px"}>
                //     {/* this will be the long form box  */}
                //     {!loading ?
                //         <>
                //             <Flex direction={"row"} justifyContent={"space-between"}>

                //                 <Text width={{ base: "90px", lg: "200px" }} as={"b"} color={"white"} fontSize={{ base: "xs", lg: "2xl" }} marginRight={{ base: "0px", lg: "0px" }} marginTop={{ base: "10px", lg: "0px" }} >{props.JobNumber}</Text>
                //                 {/* @ts-ignore */}
                //                 <Flex width={{ base: "50px", lg: "100px" }}>
                //                     <Badge marginTop={"6%"} color={props.disciplineAccent} borderColor={"var(--Able-grey)"} borderLeftColor={props.disciplineAccent} borderWidth={"3px"}
                //                         height="24px" backgroundColor={"var(--Able-grey)"} justifyItems={"center"} visibility={{ base: "hidden", lg: "initial" }}> {props.disciplineName} </Badge>
                //                 </Flex>


                //                 <Text textAlign={"left"} color={"white"} marginLeft={{ base: "-60px", lg: "0px" }} fontSize={{ base: "xs", lg: "2xl" }} marginTop={{ base: "10px", lg: "0px" }} width={{ base: "auto", lg: "100px" }}>{props.PostCode} </Text>

                //                 <Flex direction={{ base: "column", lg: "row" }}>
                //                     <Text color={"white"} textAlign={"left"} fontSize={{ base: "xs", lg: "2xl" }} marginLeft={{ base: "13px", lg: "1%" }}>
                //                         {(props.AppointmentDate).split("").slice(8, 10).join('')}
                //                         /
                //                         {(props.AppointmentDate).split("").slice(5, 7).join('')}
                //                         /

                //                         {(props.AppointmentDate).split("").slice(0, 4).join('')}
                //                     </Text>
                //                     <Text color={"white"} textAlign={"left"} fontSize={{ base: "xs", lg: "2xl" }} marginLeft={{ base: "10px", lg: "5%" }} width={{ base: "70px", lg: "200px" }}>
                //                         {(props.StartTime).split("").slice(11, 16).join("")} -
                //                         {(props.EndTime).split("").slice(11, 16).join("")}
                //                     </Text>
                //                 </Flex>
                //                 <Flex width={"20px"} justifyContent={"center"} height={{ base: "40px", lg: "40px" }} marginLeft={{ base: "0px", lg: "0px" }} marginRight={{ base: "0px", lg: "0px" }} >
                //                     {props.IsAccepted ?
                //                         <Checkbox isChecked={props.IsAccepted} colorScheme="green" />
                //                         :
                //                         <Checkbox isChecked={!props.IsAccepted} colorScheme="red" icon={<ImCross />} />
                //                     }
                //                 </Flex>
                //                 <Button colorScheme="blue" onClick={() => handleIndexChange("decrease")} backgroundColor={"var(--Able-blue)"} background={"var(--Able-blue)"} width={{ base: "1px", lg: "auto" }} size={{ base: "xs", lg: "md" }} marginTop={{ base: "7px", lg: "0px" }}><SlArrowDown color="white" /></Button>
                //                 <Button colorScheme="blue" onClick={() => handleIndexChange("increase")} backgroundColor={"var(--Able-blue)"} background={"var(--Able-blue)"} width={{ base: "1px", lg: "auto" }} size={{ base: "xs", lg: "md" }} marginTop={{ base: "7px", lg: "0px" }}><SlArrowUp color="white" /></Button>
                //                 {/* <Text color={"white"} visibility={{ base: "hidden", lg: "initial" }}>INDEX : {String(props.RouteIndex)}</Text> */}
                //                 <Flex marginBottom={"0%"} marginLeft={{ base: "0px", lg: "0px" }} marginTop={{ base: "0px", lg: "0px" }}>
                //                     <JobsheetModal {...props} />
                //                 </Flex>
                //             </Flex>
                //         </>
                //         :
                //         <Spinner color="white" margin={"75px"}></Spinner>
                //     }
                // </Box>
                <Tr bg={"backgroundGrey"} >
                    <Td borderLeftRadius={"2xl"}  borderLeftWidth={"2px"} borderLeftColor={"rgba(0,0,0,0)"} padding={{ base: "10px", lg: "auto", xl: "auto"}}><Text as={"b"} color={"primaryTextDark"} fontSize={{ base: "xs", lg: "md", xl:"2xl" }} marginRight={{ base: "0px", lg: "0px" }} marginTop={{ base: "10px", lg: "0px" }} >{props.JobNumber}</Text></Td>
                    <Td padding={{ base: "10px", xl: "auto" }} display={{ base: "none", lg: "revert" }}><Badge marginTop={"0px"} color={props.disciplineAccent} borderColor={"var(--Able-grey)"} borderLeftColor={props.disciplineAccent} borderWidth={"3px"}
                        height="24px" backgroundColor={"var(--Able-grey)"} justifyItems={"center"} > {props.disciplineName} </Badge></Td>
                    <Td display={{ base: "none", lg: "revert" }} padding={{ base: "10px", lg: "auto" }} ><Text textAlign={"left"} color={"primaryTextDark"} marginLeft={{ base: "0px", lg: "0px" }} fontSize={{ base: "xs", lg: "md", xl:"2xl" }} marginTop={{ base: "00px", lg: "0px" }}>{props.PostCode} </Text></Td>
                    <Td padding={{ base: "10px", lg: "auto" }} >
                        <Flex direction={"column"}>
                            <Text color={"primaryTextDark"} textAlign={{base: "center", lg: "left"}} fontSize={{ base: "xs", lg: "md", xl:"2xl" }} marginLeft={{ base: "0px", lg: "0px" }}>
                                {(props.AppointmentDate).split("").slice(8, 10).join('')}
                                /
                                {(props.AppointmentDate).split("").slice(5, 7).join('')}
                                /
                                {(props.AppointmentDate).split("").slice(0, 4).join('')}
                            </Text>
                            <Text color={"primaryTextDark"} textAlign={"center"} fontSize={{ base: "xs", lg: "md", xl:"2xl" }} marginLeft={{ base: "0px", lg: "0%" }} display={{ base: "revert", lg: "none" }} >
                                {(props.StartTime).split("").slice(11, 16).join("")} -
                                {(props.EndTime).split("").slice(11, 16).join("")}
                            </Text>
                        </Flex>
                    </Td>
                    <Td padding={{ base: "10px", lg: "auto" }} display={{ base: "none", lg: "revert" }} >
                        <Text color={"primaryTextDark"} textAlign={"left"} fontSize={{ base: "xs", lg: "md", xl:"2xl" }} marginLeft={{ base: "0px", lg: "0%" }} >
                            {(props.StartTime).split("").slice(11, 16).join("")} -
                            {(props.EndTime).split("").slice(11, 16).join("")}
                        </Text>
                    </Td>
                    <Td padding={{ base: "0px", lg: "auto" }} paddingLeft={{base: "0px", lg: "0px"}} textAlign={{base:"center", lg: "start"}}> {props.IsAccepted ?
                        <Checkbox isChecked={props.IsAccepted} colorScheme="green" visibility={props.complete ? "hidden": "revert"}  alignSelf={"center"}/>
                        :
                        <Checkbox isChecked={!props.IsAccepted} colorScheme="red" icon={<ImCross />} visibility={props.complete ? "hidden": "revert"} />
                    }
                    </Td>
                    <Td padding={{ base: "10px", lg: "auto" }} >
                        <Flex visibility={props.complete ? "hidden": "revert"}>
                        <Button _hover={{ bg: props.isActive ? "accentLight" : "var(--Text-strong)" }} colorScheme="blue" onClick={() => handleIndexChange("decrease")} backgroundColor={"backgroundGrey"} width={{ base: "1px", lg: "auto" }} size={{ base: "xs", lg: "md" }} marginTop={{ base: "7px", lg: "0px" }}><SlArrowDown fill={"textPrimaryDark"}/></Button>
                        <Button _hover={{ bg: props.isActive ? "accentLight" : "var(--Text-strong)" }} colorScheme="blue" onClick={() => handleIndexChange("increase")} backgroundColor={"backgroundGrey"} width={{ base: "1px", lg: "auto" }} size={{ base: "xs", lg: "md" }} marginTop={{ base: "7px", lg: "0px" }}><SlArrowUp fill={"textPrimaryDark"} /></Button>
                        </Flex>
                    </Td>
                    <Td borderRightRadius={"2xl"} borderRightWidth={"2px"} borderRightColor={"rgba(0,0,0,0)"} padding={{ base: "10px", lg: "auto" }} ><JobsheetModal {...props} returnURL={props.returnURL} view={props.view}/></Td>
                </Tr>
                :
                <Box alignContent={"center"} backgroundColor={"var(--Able-grey)"} margin={{ base: "1%", lg: "1%" }} padding={{ base: "10px", lg: "10px", xl: "20px" }} width={{ base: "98%", lg: "300px", xl: "400px" }}
                    borderColor={props.disciplineAccent} borderWidth={"2.5px"}
                    borderRadius={"lg"} height={{ base: "167px", lg: "135px", xl: "170px" }}>
                    {/* regular card box */}
                    {!loading ?
                        <>
                            <Flex direction={"column"}>
                                <Flex direction={"row"}>
                                    <Flex width={"350px"}>
                                        <Text as={"b"} color={"white"} fontSize={{ base: "xl", lg: "md" }}>{props.JobNumber}</Text>
                                        {/* @ts-ignore */}
                                        <Badge marginLeft={"3%"} color={props.disciplineAccent} marginTop={"2%"} borderColor={"var(--Able-grey)"} borderLeftColor={props.disciplineAccent} borderWidth={"3px"}
                                            height="24px" marginBottom={"0%"} backgroundColor={"var(--Able-grey)"} > {props.disciplineName} </Badge>
                                    </Flex>
                                    <Flex width={"50%"} justifyContent={"right"} fontSize={{ base: "xl", lg: "md" }}>
                                        <Text textAlign={"right"} color={"white"} >{props.PostCode}</Text>
                                    </Flex>
                                </Flex>
                                <Flex direction={"column"} justifyContent={"left"}>
                                    <Text color={"white"} textAlign={"left"} fontSize={{ base: "xl", lg: "md" }}>
                                        {(props.AppointmentDate).split("").slice(8, 10).join('')}
                                        /
                                        {(props.AppointmentDate).split("").slice(5, 7).join('')}
                                        /
                                        {(props.AppointmentDate).split("").slice(0, 4).join('')}
                                    </Text>

                                    <Text color={"white"} textAlign={"left"} fontSize={{ base: "xl", lg: "md" }}>
                                        {(props.StartTime).split("").slice(11, 16).join("")} -
                                        {(props.EndTime).split("").slice(11, 16).join("")}</Text>
                                    <Flex direction={"row"}>

                                        <Flex justifyContent={"right"}>

                                        </Flex>

                                    </Flex>

                                </Flex>
                            </Flex>

                            <Flex direction={"column"}>
                                <Flex justifyContent={"right"}>
                                    <JobsheetModal {...props} returnURL={props.returnURL} view={props.view}/>
                                </Flex>
                                {props.IsAccepted === false && props.EngineerId === auth.user?.engineer_id &&
                                    <Flex height={"10px"}>
                                        <JobBoxModal {...props} cb={props.cb} />
                                        <Refusal {...props} cb={props.cb} />
                                    </Flex>
                                }
                            </Flex>
                        </>
                        :
                        <Spinner color="white" margin={"75px"}></Spinner>

                    }
                </Box>
            }
        </>
    )

}
