import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import logo from './logo.svg';
import './App.scss';
import { SignIn } from './pages/login';
import { BrowserRouter } from 'react-router-dom';
import { PageRoutes } from './routes/Routing';
import CookieConsent from 'react-cookie-consent';
const theme = extendTheme({
  semanticTokens: {
    colors: {
      accent: {
        default: '#0173C7',
        _dark: '#66b3ff',
      },
      primaryTextDark:{
        default: "#595959",
        _dark: "#595959",
      },
      accentLight: {
        default: '#ceebfd',
        _dark: "#004080"
      },
      accentDark: {
        default: "#014a7f",
        _dark: "#99ccff"
      },
      backgroundGrey: {
        default: '#F8F8F8',
        _dark: 'gray.700'
      },
      backgroundDarkGrey: {
        default: '#b3b3b3',
        _dark: '#4f607d',
      },
      primary: {
        default: '#ccffcc',
        _dark: '#353d4b'
      },
      wrapping: {
        default: "#ffcc66",
        _dark: "#664200",
      },
      dialPadBg: {
        default: "#38a169",
        _dark: "#00c543"
      },
      dialPadBgHover: {
        default: "#2e8456",
        _dark: "#00e64d",
      },
      dialPadBgActive: {
        default: "#1b4b31",
        _dark: "#1aff66",
      },
      primaryText: {
        default: 'white',
        _dark: 'gray.800'
      },
      primaryTextInverted: {
        default: 'gray.800',
        _dark: 'white'
      },
      hangUpBg: {
        default: "#ff3333",
        _dark: "#ff2323"
      },
      hangUpHoverBg: {
        default: "#ff1a1a",
        _dark: "#ff4d4d"
      },
      hangUpActiveBg: {
        default: "#cc0000",
        _dark: "#ff8080"
      },
      greenBackground: {
        default: "#c4f0cd",
        _dark: "#5d9769"
        // _dark: "#2d833f"
      },
      greenForeground: {
        default: "#5d9769",
        _dark: "#c4f0cd"
        // _dark: "#55ff77"
      },
      yellowBackground: {
        default: "#ffeb9c",
        _dark: "#c79d43"
      },
      yellowForeground: {
        default: "#c79d43",
        _dark: "#ffeb9c"
      },
      redBackground: {
        default: "#ffc7ce",
        _dark: "#b45349"
      },
      redForeground: {
        default: "#b45349",
        _dark: "#ffc7ce"
      }
    }
  },
  breakpoints: {
    base: "0px",
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1921px",
  },

  layerStyles: {
    secondaryBackground: {
      bg: 'backgroundGrey'
    }
  },


});

function App() {
  return (
    <>
      
      <ChakraProvider theme={theme}>
        <div className="App">
          <BrowserRouter>
            <PageRoutes/>
          </BrowserRouter>
        </div>
      </ChakraProvider>

      <CookieConsent buttonStyle={{background: "var(--Able-blue)", color: "white"}}>
        This website uses cookies to enhance the user experience.
        <p style={{fontSize: "12px"}}>By continuing to use this service, you accept the use of these cookies.</p>
      </CookieConsent>
    </>
  );
}

export default App;
