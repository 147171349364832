import { IAddress } from "./IAddress";

export enum ContractorRelationship { Office = 0, PAYE = 10, Able = 20, Contractor = 30 }
export enum AllocationStyle { FreeAgent = 0, Ringmaster = 1 }
export enum ContractorStatus { Active = 0, OnHold = -50, Closed = -100 }

export interface IContractor {
    id: string,
    created: string,
    status: ContractorStatus,
    sub_status_id: string | null,
    reference: string | null,
    relationship: ContractorRelationship,
    name: string,
    address: IAddress,
    is_cis: boolean,
    cis_rate: number,
    is_vat: boolean,
    allocation_style: AllocationStyle,
    default_engineer_id: string,
    version: string   
}