import { SortByGroupSummaryInfoItem } from "devextreme/ui/data_grid"
import { StringMappingType } from "typescript"

export interface IWorkResult {
    AddressLine: string,
    AllocationId: string,
    AllocationStatus: number,
    AppointmentDate: string,
    AppointmentId: string,
    DisciplineId: string,
    EndTime: string,
    EngineerId: string,
    EngineerName: string,
    Expected: string,
    IsAccepted: boolean,
    IsAccountWork: boolean,
    IsOnOrder: boolean,
    IsOrderLate: boolean,
    JobNumber: string,
    PostCode: string,
    StartTime: string,
    value: number,
    cb: Function,
    ringmaster: string,
    disciplineName: string,
    disciplineAccent: string,
    isActive: boolean,
    RouteIndex: number,
    refreshCb: Function,
    complete: boolean
    returnURL: string,
    view: number,
}

export interface IDisciplineResult {
    accent: string,
    friendly_id: string,
    id: string,
    name: string,
    sl_nominal_code: number,
}

export enum JobsheetStatus {
    Cancelled = -100,
    PendingCancellation = -50,
    OnQuery = -1,
    Pending = 0,
    PreCompletion = 70,
    EngineerToClear = 80,
    NacPending = 90,
    Completed = 100
}

export enum AllocationStatus {
    Allocated = 0,
    OnRoute = 10,
    OnSite = 50,
    Completed = 100
}

export interface IJobsheet {
    id: string,
    works_type: number,
    booking_source: string,
    version: string,
    created: string,
    created_by: string,
    discipline_id: string,
    status: JobsheetStatus,
    status_reason_id: string | null,
    status_sub_reason_id: string | null,
    status_last_updated: string,
    status_last_updated_by: string,
    status_reason?: IStatusReason | null,
    completed_date: string | null,
    completed_by_id: string | null,
    is_verified: boolean,
    customer_id: string,
    customer: ICustomer | null,
    premise_type: number,
    right_to_cancel: IRightToCancel | null,
    site_id: string | null,
    address: IAddress,
    value_vat_code: string,
    value_materials_nett: number,
    value_labour_nett: number,
    value_nett: number,
    value_vat: number,
    value_gross: number,
    value_estimated_nett: number,
    value_estimated_vat: number,
    value_estimated_gross: number,
    value_paid: number,
    value_discount_requested: number,
    value_discount_is_percentage: boolean,
    value_discount_applied: number,
    value_discount_offer_id: string | null,
    value_deposit_expected: number | null,
    value_method: number,
    value_nte_agreed: number | null, 
    value_autocalc: boolean,
    appointment_id: string | null,
    appointment_last_id: string,
    appointment: IAppointment | null,
    awaiting_acceptance: boolean,
    is_invoice_requested: boolean,
    is_dual_visit: boolean,
    tax_date: string | null,
    contact_caller_id: string,
    contact_billpayer_id: string,
    contact_site_id: string,
    assigned_agent_id: string | null,
    order_number: string | null,
    works_report: IWorkReport | null,
    works_report_id: string,
    routeIndex: number,
    is_site_contact_billing_authorised: boolean | null, 
}

export interface IAddress {
    line1: string | null,
    line2: string | null,
    line3: string | null,
    line4: string | null,
    post_code: string | null
}

export interface TypoAddress {
    Line1: string | null,
    Line2: string | null,
    Line3: string | null,
    Line4: string | null,
    PostCide: string | null
}

export interface IAppointment {
    id: string,
    created: string,
    created_by_id: string,
    status: number,
    job_number: string,
    date: string,
    start_date: string,
    end_date: string,
    type?: number| null,
    chased: boolean | null,
    notes: string | null,
    required_warning_time: number,
    eta: IEta | null,
    allocation: IAllocation | null,
    suggested_engineer_id?: string | null,
    version: string
}

export interface IEta {
    time: string,
    aware: boolean,
    updated: string
}

export interface IAllocation {
    id: string,
    created: string,
    createdby_id: string,
    status: number,
    appointment_id: string,
    job_number: string,
    notes: string | null,
    accepted: boolean,
    engineer_id: string,
    progress: string,
    on_route_timestamp: string | null,
    on_site_timestamp: string | null,
    completed_timestamp: string | null,
    engineer: IEngineer | null
}

export interface IEngineer {
    id: string,
    created: string,
    is_enabled: boolean,
    contractor_id: string,
    name: string,
    address: IAddress,
    mobile_number_1: string,
    mobile_number_2: string | null,
    land_line_number: string,
    email_address: string,
    speed_dial: number,
    default_contact_method: number,
    push_enabled: boolean,
    push_platform: number,
    allocation_threshold: number,
    contractor: IContractor | null,
    version: string
}

export interface IContractor {
    id: string,
    helios_reference: string,
    created: string,
    status: number,
    sub_status_id: number | null,
    reference: string | null,
    relationship: number,
    name: string,
    address: IAddress,
    is_cis: boolean,
    cis_rate: number,
    is_vat: boolean,
    allocatiion_style?: number,
    default_engineer_id: string,
    version: string
}

export interface ICustomer {
    Id: string,
    Created: string,
    Name: string,
    Type: number,
    Status: number,
    WebSite: string | null,
    WebPortal: string | null,
    ManagerId: string,
    DefaultContactId: string,
    send_confirmation: boolean,
    requires_order_number: boolean,
    is_vat: boolean,
    is_cis: boolean,
    reverse_charge_type: number,
    reverse_charge_notice_received: boolean,
    vat_number: string | null,
    DefaultContact: IContact | null,
    ServiceTier: IServiceTier | null,
    HeadOffice: {
        Address: IAddress
    },
    Billing: {
        Address: IAddress,
        ContactId: string,
        Contact: IContact
    },
    product_restriction_id: string | null,
    defaults: {
        default_nte: number
    }
}

export interface IContact {
    Id: string,
    Salutation: string,
    FirstName: string,
    LastName: string,
    Role: string,
    MobileNumber: string | null,
    LandLine: string | null,
    EmailAddress: string | null,
    Address: TypoAddress
}

export interface IServiceTier {
    id: string,
    name: string,
    default_prefix: string | null,
    index: number
}

export interface IStatusReason {
    id: string,
    friendly_id: string | null,
    status_type: number,
    name: string,
    notes: string | null
}
export enum WiplineStatus {
    Pending = 0,
    Orderable = 10,
    ToOrder = 40,
    OnOrder = 50,
    Ready = 60,
    Completed = 100
}

export interface IWipLine {
    id: string, 
    created: string,
    created_by: string, 
    status: WiplineStatus,
    job_number: string,
    quantity: number,
    name: string,
    engineer_id: string,
    supplier_id: string | null,
    order_number: string | null,
    line_type: string,
    notes: string | null,
    time_zone_id: string,
    is_assessed: boolean,
    is_re_assessment: boolean,
    is_order_line: boolean,
    is_picked_up: boolean,
    is_damage_line: boolean,
    expected_date: string | null,
    price_description: string,
    trade_price: number,
    manual_cossh_id: string|  null,
    line_data: {
        product_id:string, 
        calculate_price_description?: string,
        modifiers: string[],
        creation_plan_id?: string,
        time_zone_id?: string,
        requirements: string[]
    },
    version:string
}

export interface IWipLineWithCb {
    id: string, 
    created: string,
    created_by: string, 
    status: WiplineStatus,
    job_number: string,
    quantity: number,
    name: string,
    engineer_id: string,
    supplier_id: string | null,
    order_number: string | null,
    line_type: string,
    notes: string | null,
    time_zone_id: string,
    is_assessed: boolean,
    is_re_assessment: boolean,
    is_order_line: boolean,
    is_picked_up: boolean,
    is_damage_line: boolean,
    expected_date: string | null,
    price_description: string,
    trade_price: number,
    manual_cossh_id: string|  null,
    line_data: {
        product_id:string, 
        calculate_price_description?: string,
        modifiers: string[],
        creation_plan_id?: string,
        time_zone_id?: string,
        requirements: string[]
    },
    version:string,
    cb: Function,
    isPAYE: number,
}

export interface IWipLines {
    lines: IWipLine[]
}
export interface IRightToCancel {
    id: string;
    job_number: string;
    is_signed: boolean;
    allow_digital: boolean;
    signed: IRightToCancelSigned | null;
}

export interface IRightToCancelSigned {
    date: string;
    signee: string | null;
    signee_email_address: string | null;
}
export interface IWorkReport {
    Created: string,
    Details: string | null,
    Id: string,
    IsSigned: boolean,
    JobNumber: string,
    SatisfactionRating: number,
    SignatureId: string | null,
    SignedDate: string | null,
    SigneeName: string | null,
    // id: string;
    // created: string;
    // job_number: string;
    // is_signed: boolean;
    // signed_date: string | null;
    // signee_name: string | null;
}

export enum SatisfactionRating {
    undefined = -1,
    UnHappy = 0, 
    InDifferent = 1, 
    Happy = 2
}