import * as React from 'react';
import { ReactNode, createContext, useContext } from "react";
import { AllocationStyle } from "../interfaces/service/IContractor";
import { useAuthProvider } from "./AuthProvider";
import { IEngineer } from '../interfaces/service/IEngineer';

export interface IUser {
    engineer_id: string,
    engineer_name: string,
    contractor_id: string,
    contractor_type: AllocationStyle | null,
    contractor_name: string,
}

interface AuthContextType {
    user: IUser | null,
    loading: boolean,
    signInState: boolean,
    signIn: (userName: string, passWord: string, remember: boolean) => Promise<boolean>;
    signOut: () => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({children}: {children: ReactNode}) {
    const auth = useAuthProvider();

    if (auth.loading) {
        return <h1>Loading ...</h1>
    }

    return (
        <AuthContext.Provider value={auth} >
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return React.useContext(AuthContext);
}