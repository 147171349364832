import React, { useEffect, useMemo } from "react";
import { IWhiteboard } from "./modal";
import { Badge, Flex, Text } from "@chakra-ui/react";
import { formatDate } from "devextreme/localization";

interface AppointmentProps {
    data: AppointmentDataProps,
    index: number,
    allocationJobNumber: string,
}

interface AppointmentDataProps {
    appointmentData: IWhiteboard,
    targetedAppointmentData: IWhiteboard,
}

export const Appointment = (props: AppointmentProps) => {
    useEffect(() => {
        console.log("Appointment props : ", props);
    })
    return (
        <>
            <Flex direction={"column"} height={"130%"} w={"120%"} marginLeft={props.data.appointmentData.text === props.allocationJobNumber ?  "-5px" : "0px"} 
            marginTop={props.data.appointmentData.text === props.allocationJobNumber ? "-5px" : "0px"}
            className={props.data.appointmentData.text === props.allocationJobNumber ? "jobToAllocate" : "normalJob"}
            >
                <Flex direction={"row"}>
                    <Text as={"b"} fontSize={"md"} marginLeft={"5px"}>{props.data.appointmentData.text}</Text>
                    
                </Flex>
                <Text marginLeft={"5px"} fontSize={"2xs"} textOverflow={"clip"}>{formatDate(props.data.appointmentData.startDate, 'shortTime')}
                    {' - '}
                    {formatDate(props.data.appointmentData.endDate, 'shortTime')}</Text>
                    {props.data.appointmentData.text === props.allocationJobNumber && <Badge marginLeft={"5px"} variant={"solid"} colorScheme="green" w={"75px"}>Assigning</Badge>}

            </Flex>
        </>
    )
}