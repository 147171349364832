import * as React from 'react';
import { Button, Flex, Heading, HStack, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { PageContainer } from '../../components/container';

export default function NotAuthorisedPage() {
    let navigate = useNavigate();

    return (
            <Flex w='full' align='center' direction='column' marginTop={"100px"} gap={6} height={"60%"}>
                <Heading size='4xl' textColor='var(--Able-blue)'>401</Heading>
                <Heading size='xl' textAlign='center'>Sorry! You can't access this page.</Heading>
                <Button colorScheme='blue' variant='link' size='lg' onClick={() => navigate("/dashboard")}>Go back to dashboard</Button>
            </Flex>
    );
}