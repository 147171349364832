import { PageContainer } from '../../components/container';
import { useAuth } from '../../context/AuthContext';
import { RingmasterHomePage } from '../ringmaster/home';

export const Dashboard = () => {
    const auth = useAuth();

    return (
        <PageContainer pageTitle='Dashboard'>
            <RingmasterHomePage/>
        </PageContainer>

        // <PageContainer pageTitle='Dashboard'>
        //     { auth.user?.contractor_type === AllocationStyle.Ringmaster ?
        //         <RingmasterHomePage/>
        //         :
        //         <Homepage/>
        //     }
        // </PageContainer>
    )
}
