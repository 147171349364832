import * as React from 'react';
import { Button, Flex, Heading, HStack, Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import { PageContainer } from '../../components/container';

export default function NotFoundPage() {
    let navigate = useNavigate();

    return (
        <PageContainer pageTitle="404 Not Found">
            <Flex w='full' align='center' direction='column' m={8} gap={6}>
                <Heading size='4xl' textColor='var(--Able-blue)'>404</Heading>
                <Heading size='xl' textAlign='center'>Oops! We can't seem to find that page.</Heading>
                <Button colorScheme='blue' variant='link' size='lg' onClick={() => navigate("/dashboard")}>Go back to dashboard</Button>
            </Flex>
        </PageContainer>
    );
}