import {
    useDisclosure,
    Badge,

} from "@chakra-ui/react"
import { Fade, Progress, Button, Input, Flex, Heading, InputGroup, InputLeftAddon, Text, Card, CardHeader, CardBody, Checkbox, Stack } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import "../../App.scss"
import { MdRemoveCircleOutline } from "react-icons/md";
import { BsPersonFill, BsThreeDots } from "react-icons/bs";
import { IContractor, IWorkReport, IWorkResult, WiplineStatus } from "../../interfaces/service/Interfaces";
import { IDisciplineResult } from "../../interfaces/service/Interfaces";
import { IJobsheet } from "../../interfaces/service/Interfaces";
import { AllocationStatus } from "../../interfaces/service/Interfaces";
import { IWipLine } from "../../interfaces/service/Interfaces";
import { useParams } from "react-router-dom";
import { PageContainer } from '../../components/container';
import { useAuth } from "../../context/AuthContext";
import { AllocationStyle } from "../../interfaces/service/IContractor";
import { useWebHelper } from "../../hooks/UseWebHelper";
import { BsFillHouseFill } from "react-icons/bs";
import { IoCalendar, IoWarning } from "react-icons/io5"
import { AiFillClockCircle } from "react-icons/ai"
import { FaFileSignature } from "react-icons/fa"
import { AiFillPhone } from "react-icons/ai";
import { MdSmartphone } from "react-icons/md";
import { FaPoundSign } from "react-icons/fa";
import { BsFillClipboardDataFill } from "react-icons/bs";
import { useCache } from "../../context/CacheContext";
import { ToOrderModal } from "./toOrderModal";
import { ReadyModal } from "./readyModal";
import moment from "moment";
import { IRightToCancel } from "../../interfaces/service/Interfaces";
import { GoAlert } from "react-icons/go"
import { Questions } from "./questions";
import { BiHardHat } from "react-icons/bi"
import NotFoundPage from "../errors/NotFound";
import NotAuthorisedPage from "../errors/NotAuthorised";

interface IQuestions {
    id: string
    content: IQuestionContent[] | undefined
}
interface IQuestionContent {
    answer: string,
    question: string,
    type: string,
}
interface ISiteContact {
    EmailAddress: string,
    FirstName: string,
    Id: string,
    LandLine: string,
    LastName: string,
    MobileNumber: string,
    Role: string,
    Salutation: string,

}
interface INotes {
    id: string,
    created: string,
    created_by_id: string,
    object_reference: string,
    message: string,
    tag_id: string,
    tag: ITag,
}
interface ITag {
    id: string,
    object_type: string,
    read_permission: string | null,
    write_permission: string | null,
    name: string,
}
export const JobsheetPage = () => {
    const auth = useAuth();
    const webHelpers = useWebHelper();
    const cache = useCache();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const engineer_id = auth.user?.contractor_id;
    const [jobDiscipline, setDiscipline] = useState<string>("");
    const [jobSheet, setJobsheet] = useState<IJobsheet | null>();
    const [textAllocation, setTextAllocation] = useState<string>("");
    const [wipLines, setWipLines] = useState<IWipLine[]>();
    const { jobNumber, selectedView, filter, search } = useParams();
    const [ignore, setIgnore] = useState<boolean>(false);
    const [worksheet, setWorksheet] = useState<IWorkResult>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingValue, setLoadingValue] = useState<number>(0);
    const [engineerName, setEngineerName] = useState<string>();
    const [disciplineList, setDisciplineList] = useState<IDisciplineResult[]>();
    const [accent, setAccent] = useState<string>("blue");
    const [questions, setQuestions] = useState<IQuestions[]>([]);
    const [rtc, setRTC] = useState<IRightToCancel>();
    const [wr, setWR] = useState<IWorkReport>();
    const [siteContact, setSiteContact] = useState<ISiteContact>();
    const [notes, setNotes] = useState<INotes[]>([]);
    const [cis, setCis] = useState<boolean>(false);
    const [authorised, setAuthorised] = useState<boolean>(true);

    const [isPAYE, setIsPAYE] = useState<number>(0);



    const Navigate = useNavigate();

    const handleSubmit = () => {
        //setAccept()
        //engineerId is tbd

    }
    const getWork = async () => {
        return await webHelpers.GetAsync<IWorkResult[] | null>(
            "/api/app/engineer/work/peg", "sales"
        ).then((res) => {
            setLoadingValue(20);
            if (res !== null) {
                let found = false
                res.forEach(each => {
                    if (each.JobNumber === jobNumber) {
                        found = true;
                        setWorksheet(each);
                        console.log("worksheet = ", each);
                        getDisciplineFromId(each.DisciplineId);
                        getJobsheet(each.JobNumber);
                        setTextAllocationStatus(each.AllocationStatus);
                    }
                });
                if (!found) {
                    webHelpers.GetAsync<any>(
                        "/api/service/contracting/contractor/completedwork?id=" + auth.user?.contractor_id + "&days=28&filter=" + jobNumber, "sales"
                    ).then((res) => {
                        console.log("res = ", res);
                        if (res.length > 0) {
                            let found = false;
                            res.forEach((each: any) => {
                                console.log("peg res = ", res)
                                if (each.JobNumber === jobNumber) {
                                    found = true;
                                    setWorksheet(each);
                                    getDisciplineFromId(each.DisciplineId);
                                    getJobsheet(each.JobNumber);
                                    setTextAllocationStatus(each.AllocationStatus);
                                    console.log("getWork res", res)
                                    getWipLines();
                                }
                            });
                        }
                        else {
                            setAuthorised(false);
                            setLoading(true);
                            console.log(console.clear());
                            console.log("(401): The Jobsheet you're trying to open is not assigned to the Enginner who's logged in!")
                        }
                    })
                }
            }
        })
    }
    const getWorkRingmaster = async () => {
        return await webHelpers.GetAsync<IWorkResult[] | null>(
            "/api/service/contracting/contractor/outstandingwork?id=" + auth.user?.contractor_id, "sales"
        ).then((res) => {
            setLoadingValue(20);
            if (res !== null) {
                let found = false;
                res.forEach(each => {
                    if (each.JobNumber === jobNumber) {
                        found = true;
                        setWorksheet(each);
                        getDisciplineFromId(each.DisciplineId);
                        getJobsheet(each.JobNumber);
                        setTextAllocationStatus(each.AllocationStatus);
                    }
                });
                if (!found) {

                    webHelpers.GetAsync<any>(
                        "/api/service/contracting/contractor/completedwork?id=" + auth.user?.contractor_id + "&days=28&filter=" + jobNumber, "sales"
                    ).then((response) => {
                        console.log("res = ", response);
                        if (response.length > 0) {
                            response.forEach((each: any) => {
                                console.log("peg res = ", response)
                                if (each.JobNumber === jobNumber) {
                                    setWorksheet(each);
                                    getDisciplineFromId(each.DisciplineId);
                                    getJobsheet(each.JobNumber);
                                    setTextAllocationStatus(each.AllocationStatus);
                                }
                            });
                        }
                        else {
                            setAuthorised(false);
                            setLoading(true);
                            console.log(console.clear());
                            console.log("(401): The Jobsheet you're trying to open is not assigned to the Enginner who's logged in!");
                        }
                    })
                    getCompleteReport();
                    getCompleteWork();
                    if (jobNumber !== undefined && jobNumber !== null) {
                        getJobsheet(jobNumber);
                    }
                }
            }
            else {
                console.log("peg is null")
            }
            console.log("getWorkRingmaster res", res)
            getWipLines();

        })
    }
    const getCompleteReport = async () => {
        return await webHelpers.GetAsync<any>(
            "/api/service/contracting/contractor/completedreport?id=" + auth.user?.contractor_id + "&days=28&filter=" + jobNumber, "sales"
        ).then((res) => {
            // console.log("completed report = ", res);

        })
    }
    const getCompleteWork = async () => {
        return await webHelpers.GetAsync<any>(
            "/api/service/contracting/contractor/completedwork?id=" + auth.user?.contractor_id + "&days=28&filter=" + jobNumber, "sales"
        ).then((res) => {
            // console.log("completed work = ", res);
            setTextAllocationStatus(res[0].AllocationStatus);
            getDisciplineFromId(res[0].DisciplineId);
            getAllocation(res[0].appointment_id);
            setWorksheet(res[0]);
            getEngineers().then((engineerList) => {
                if (engineerList[0].includes(res[0].engineer_id)) {
                    console.log("authorised");
                }
                else {
                    Navigate("/dashboard");
                }
            })

        })
    }
    const getWR = async (id: string) => {
        return await webHelpers.GetAsync<any>(
            "/api/sales/work/workreport/get?id=" + id, "sales"
        ).then((res) => {
            console.log("work report = ", res)
            setWR(res);
        })
    }
    const getRTC = async () => {
        return await webHelpers.GetAsync<any>(
            "/api/sales/work/jobsheet/rtc?id=" + jobNumber, "sales"
        ).then((res) => {
            setRTC(res);
        })
    }
    const getAllocation = async (appointmentId: string) => {
        return await webHelpers.GetAsync<any>(
            "/api/sales/work/allocation/appointment?id=" + appointmentId, "sales"
        )
    }
    const getEngineerId = async (allocationId: string) => {
        return await webHelpers.GetAsync<any>(
            "/api/sales/work/allocation/get?id=" + allocationId, "sales"
        )
    }
    const getEngineerName = async (engineerId: string) => {
        return await webHelpers.GetAsync<any>(
            "/api/service/contracting/engineer/get?id=" + engineerId, "sales"
        )
    }
    const getSiteContact = async (siteContactId: string | undefined) => {
        console.log("/api/core/universal/contacts?id=" + siteContactId)
        return await webHelpers.GetAsync<any>(
            "/api/core/universal/contacts/get/" + siteContactId, "sales"
        )
    }
    const getEngineers = async () => {
        return await webHelpers.GetAsync<any>(
            "/api/service/contracting/engineer/forcontractor?id=" + auth.user?.contractor_id, "sales"
        )
    }
    const getJobsheet = async (localJobNumber: string) => {
        return await webHelpers.GetAsync<IJobsheet | null>(
            "/api/sales/work/jobsheet/get?id=" + localJobNumber, "sales"
            //undefined, JS // Comment this line out when running server
        ).then((res) => {
            setJobsheet(res);
            getNotes(res?.id);
            // console.log("jobsheet res", res);
            if (res?.contact_caller_id !== res?.contact_site_id) {
                getSiteContact(res?.contact_site_id).then((res) => {
                    setSiteContact(res);
                })
            }
            setLoadingValue(65);
            getRTC();
            setLoadingValue(70);
            if (res?.works_report_id !== undefined) {
                getWR(res?.works_report_id);
            }
            setLoadingValue(75);
            if (res?.appointment_id !== null && res?.appointment_id !== undefined) {
                getAllocation(String(res?.appointment_id)).then((reply) => {
                    // console.log("allocations reply = ", reply)
                    setLoadingValue(80);
                    getEngineerId(reply[0].id).then((reply2) => {
                        setLoadingValue(95)

                        getEngineerName(reply2.engineer_id).then((reply3) => {
                            setLoadingValue(90)
                            setEngineerName(reply3.name);
                            setLoading(false);
                        })
                    })
                })
            }
            else {
                getAllocation(String(res?.appointment_last_id)).then((reply) => {
                    // console.log("allocations reply = ", reply)
                    setLoadingValue(80);
                    getEngineerId(reply[0].id).then((reply2) => {
                        setLoadingValue(95)

                        getEngineerName(reply2.engineer_id).then((reply3) => {
                            setLoadingValue(100)
                            setEngineerName(reply3.name);
                            setLoading(false);
                        })
                    })
                })
            }
        })
    };
    useEffect(() => {
        // console.log("jobsheet = ", jobSheet)
    }, [jobSheet]);

    const getWipLines = async () => {
        return await webHelpers.GetAsync<IWipLine[]>(
            "/api/sales/work/jobsheet/wiplines/" + jobNumber, "sales"
        ).then((res) => {
            if (res !== null) {
                // console.log("wiplines ", res);
                let loop = res;
                loop.forEach((each) => {
                    getAssessmentPlans(each.id)
                })
                setWipLines(res);
                // console.log("wiplines = ", res);
                setLoadingValue(50);
                getIsCis();

            }
        })
    }
    const getAssessmentPlans = async (id: string) => {
        setQuestions([]);
        return await webHelpers.GetAsync<any>(
            "/api/sales/work/wip/creationpath?id=" + id, "sales"
        ).then((res) => {
            // console.log("assessment plans response", res);
            // if (res === undefined || res === null) {
            getFluidJson(res).then((response) => {
                let data = {
                    id: id,
                    content: response,
                }
                // questions.push(data);
                setQuestions(questions => [...questions, data]);
            }
            )


        })
    }
    useEffect(() => {
        // console.log("questions", questions);
    }, [questions]);

    const getFluidJson = async (body: any) => {
        try {
            return await webHelpers.PostAsync<any, IQuestions[]>(
                "/api/core/universal/fluid/responses/", "sales", body
            )
        }
        catch (e: any) {
            console.log("could not run api")
        }
    }
    const setTextAllocationStatus = (allocationStatus: Number) => {
        switch (allocationStatus) {
            case AllocationStatus.Allocated:
                setTextAllocation("Allocated");
                break;
            case AllocationStatus.Completed:
                setTextAllocation("Completed");
                break;
            case AllocationStatus.OnRoute:
                setTextAllocation("On Route");
                break;
            case AllocationStatus.OnSite:
                setTextAllocation("On Site");
                break;
        }
    }
    const getNotes = async (id: string | undefined) => {
        await webHelpers.GetAsync<INotes[]>(
            "/api/core/universal/note/all/" + id + "?type=jobsheet", "sales",
        ).then((res) => {
            if (res !== null && res !== undefined) {
                setNotes(res);
            }
        })
    }
    const getDisciplineFromId = async (localDisciplineId: string) => {
        await webHelpers.GetAsync<IDisciplineResult>(
            "/api/sales/work/discipline/get?id=" + localDisciplineId, "sales"
        ).then((res) => {
            if (res !== null && res !== undefined) {
                setDiscipline(res.name);
                setLoadingValue(50);
                setAccent(res.accent);
            }
        })
    }

    const refresh = () => {
        setLoadingValue(0);
        setLoading(true);
        getRingmaster()

        if (auth.user?.contractor_type === AllocationStyle.FreeAgent) {
            getWork();
        }
        else if (auth.user?.contractor_type === AllocationStyle.Ringmaster) {
            getWorkRingmaster();
        }
        setIgnore(true);


    }
    const closeJobsheetView = () => {
        Navigate("/dashboard/" + (selectedView !== undefined ? String(selectedView) : "1") + "/" + (filter !== undefined ? String(filter) : "engineer") + (search !== undefined ? String(search) : ""));
    }
    const getProduct = async (productId: string) => {
        return await webHelpers.GetAsync<any>(
            "/api/products/catalogue/item/get/?id=" + productId, "sales"
        )
    }
    useEffect(() => {
        getIsCis();
    }, [wipLines])

    const getIsCis = () => {
        let isCis = false;
        if (wipLines !== undefined) {
            wipLines.forEach((wipline: IWipLine) => {
                getProduct(wipline.line_data.product_id).then((res) => {
                    if (res.cis_enabled) {
                        setCis(true)
                    }
                })
            });
        }
    }
    const getRingmaster = async () => {
        return await webHelpers.GetAsync<IContractor>(
            "/api/service/contracting/contractor/get?id=" + auth.user?.contractor_id, "sales"
        ).then((res) => {

            if (res !== null && res !== undefined) {
                setIsPAYE(res.relationship);
            }
        })
    }

    return (
        <PageContainer pageTitle="Jobsheet">
            {authorised ?
                <>
                    {!ignore && refresh()}
                    <Heading marginTop={"2%"} marginLeft={"5%"} textAlign={"left"} as={"p"} color={"var(--Able-blue)"}
                        borderBottomColor={"var(--Able-yellow)"} borderBottomWidth={"2px"} width={"90%"} fontSize={{ base: "xl", lg: "4xl" }} >{jobNumber + " "}
                        {!loading &&
                            <>
                                <Badge fontSize={{ base: "xs", lg: "md" }} color={"blackAlpha.800"} bg={accent}>{jobDiscipline}</Badge>
                                <Badge fontSize={{ base: "xs", lg: "md" }} colorScheme="blue" marginLeft={"10px"}>{textAllocation}</Badge>
                                <Badge fontSize={{ base: "xs", lg: "md" }} colorScheme="green" marginLeft={"10px"}>{engineerName}</Badge>

                                {jobSheet?.customer?.Type === 0 ? //0 is domestic, 50 is private business, 100 is premium
                                    <Badge fontSize={{ base: "xs", lg: "md" }} colorScheme="pink" marginLeft={"10px"}>Domestic Customer</Badge>
                                    :
                                    <>
                                        {jobSheet?.customer?.Type === 50 ?
                                            <Badge fontSize={{ base: "xs", lg: "md" }} colorScheme="orange" marginLeft={"10px"}>Commercial Customer</Badge>
                                            :
                                            <Badge fontSize={{ base: "xs", lg: "md" }} colorScheme="orange" marginLeft={"10px"}>Premium Customer</Badge>
                                        }
                                    </>
                                }
                                {cis &&
                                    <Badge fontSize={{ base: "xs", lg: "md" }} colorScheme="yellow" marginLeft={"10px"} >
                                        <Flex direction={"row"} >
                                            <BiHardHat fontSize={"20px"} />
                                            <Text marginLeft={"3px"}>CIS</Text>
                                        </Flex>
                                    </Badge>
                                }
                            </>
                        }

                    </Heading>

                    <Flex direction={{ base: "column", lg: "row", xl: "row" }} marginLeft={{base: "1%", lg: "5%", xl: "1%"}} w={"98%"}>
                        {!loading ?
                            <>

                            {/*Address show with cards*/}
                            <Flex direction={{ base: "column", lg:"row", xl: "row" }} width={{ base: "95%", lg: "90%", xl:"100%" }} marginLeft={{base: "10px", lg: "0px"}}>
                                <Flex direction={"column"} justifyContent={"left"} width={{ base: "100%", lg: "50%", xl: "50%" }} marginTop={{ base: "0px", xl: "25px" }} marginLeft={{ base: "0px",xl: "5%" }}>
                                    <Fade in={!loading}>
                                        <Flex direction={{ base: "column", lg: "row", xl: "row" }} justifyContent={"left"} marginTop={"1%"} >
                                            <Flex direction={"column"} width={{ base: "100%",lg: "49%", xl: "400px" }} marginTop={{ base: "10px", xl: "0px" }} boxShadow={"md"}>
                                                <InputGroup borderBottomColor={"white"} borderBottomLeftRadius={"0px"} >
                                                    <InputLeftAddon borderRightRadius={"0"} borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderBottomRadius={"0"}><BsFillHouseFill /></InputLeftAddon>
                                                    {/* @ts-ignore */}
                                                    <Input contentEditable={false} readOnly={true} value={" " + jobSheet?.address.post_code} borderBottomRadius={"0"} pointerEvents={"none"}></Input>
                                                </InputGroup>
                                                <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={"white"}>
                                                    <InputLeftAddon borderRightRadius={"0"} borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderRadius={"0"}><BsFillHouseFill /></InputLeftAddon>
                                                    {/* @ts-ignore */}
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={" " + jobSheet?.address.line1}></Input>
                                                </InputGroup>
                                                <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={"white"}>
                                                    <InputLeftAddon borderRightRadius={"0"} borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderRadius={"0"}><BsFillHouseFill /></InputLeftAddon>
                                                    {/* @ts-ignore */}
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={jobSheet?.address.line2}></Input>
                                                </InputGroup>
                                                <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={jobSheet?.address.line4 !== "" ? "white" : "var(--chakra-colors-chakra-border-color)"} >
                                                    <InputLeftAddon borderRightRadius={"0"} borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderRadius={"0"} borderBottomRadius={jobSheet?.address.line4 !== "" ? "0" : "lg"}><BsFillHouseFill /></InputLeftAddon>
                                                    {/* @ts-ignore */}
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={jobSheet?.address.line3}></Input>
                                                </InputGroup>
                                                {jobSheet?.address.line4 !== "" &&
                                                    <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"}>
                                                        <InputLeftAddon borderRightRadius={"0"} borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><BsFillHouseFill /></InputLeftAddon>
                                                        {/* @ts-ignore */}
                                                        <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={jobSheet?.address.line4}></Input>
                                                    </InputGroup>
                                                }


                                            </Flex>

                                            <Flex direction={"column"} marginLeft={{ base: "0px", lg: "10px",  xl: "10px" }} width={{ base: "100%",lg: "50%", xl: "400px" }} marginTop={{ base: "10px", xl: "0px" }} >
                                                <InputGroup borderBottomColor={"white"} borderBottomLeftRadius={"0px"} >
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderBottomRadius={"0"}><IoCalendar /></InputLeftAddon>
                                                    {/* @ts-ignore */}
                                                    {worksheet !== undefined && worksheet !== null &&
                                                        <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={(worksheet.AppointmentDate).split("").slice(8, 10).join('')
                                                            + "/" +
                                                            String((worksheet.AppointmentDate)).split("").slice(5, 7).join('')
                                                            + "/" +
                                                            (worksheet.AppointmentDate).split("").slice(0, 4).join('')} borderBottomRadius={"0"}></Input>
                                                    }
                                                </InputGroup>
                                                <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} boxShadow={(jobSheet?.appointment?.required_warning_time !== 0 && jobSheet?.appointment?.required_warning_time !== undefined) ? "none": "md"}>
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"} ><AiFillClockCircle /></InputLeftAddon>
                                                    {/* @ts-ignore */}
                                                    {worksheet !== undefined && worksheet !== null &&
                                                        <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={
                                                            (worksheet.StartTime).split("").slice(11, 16).join("") + "-" +
                                                            (worksheet.EndTime).split("").slice(11, 16).join("")}
                                                            borderBottomWidth={(jobSheet?.appointment?.required_warning_time === 0 || jobSheet?.appointment?.required_warning_time === undefined) ? "1px" : "0px"}></Input>
                                                    }
                                                </InputGroup>
                                                {(jobSheet?.appointment?.required_warning_time !== 0 && jobSheet?.appointment?.required_warning_time !== undefined) &&
                                                    <>
                                                        <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"}>
                                                            <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"} borderBottomLeftRadius={0}><GoAlert /></InputLeftAddon>
                                                            {/* @ts-ignore */}
                                                            {worksheet !== undefined && worksheet !== null &&
                                                                <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={"You must call the customer "}
                                                                    borderBottomWidth={jobSheet?.appointment?.required_warning_time === 0 ? "10px" : "0px"}></Input>
                                                            }
                                                        </InputGroup>
                                                        <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} boxShadow={"md"}>
                                                            <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><GoAlert /></InputLeftAddon>
                                                            {/* @ts-ignore */}
                                                            {worksheet !== undefined && worksheet !== null &&
                                                                <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={jobSheet?.appointment?.required_warning_time + " minutes before you arrive"}></Input>
                                                            }
                                                        </InputGroup>
                                                    </>
                                                }
                                            </Flex>
                                        </Flex>
                                        <Flex direction={{ base: "column", xl: "row" }} justifyContent={"left"} marginTop={"10px"}>
                                            <Flex direction={"column"} width={{ base: "100%", xl: "400px" }} marginLeft={"0px"} >

                                                <InputGroup boxShadow={"md"} >
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} ><BsPersonFill /></InputLeftAddon>
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={" " + jobSheet?.customer?.DefaultContact?.Salutation + " " + jobSheet?.customer?.DefaultContact?.FirstName + " " + jobSheet?.customer?.DefaultContact?.LastName + " (" + jobSheet?.customer?.DefaultContact?.Role + ") "}></Input>
                                                </InputGroup>

                                                {/* <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={"white"}>
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderTopColor={"var(--Able-blue)"} borderRadius={"0"}><MdSmartphone /></InputLeftAddon>
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={jobSheet?.customer?.DefaultContact?.MobileNumber ? " " + jobSheet?.customer?.DefaultContact?.MobileNumber : " N/A"}></Input>
                                                </InputGroup>

                                                <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"}>
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><AiFillPhone /></InputLeftAddon>
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={jobSheet?.customer?.DefaultContact?.LandLine ? " " + jobSheet?.customer?.DefaultContact?.LandLine : " N/A"}></Input>
                                                </InputGroup> */}

                                            </Flex>


                                            <Flex direction={"column"} width={{ base: "100%", xl: "400px" }} marginLeft={{ base: "0px", xl: "10px" }} marginTop={{ base: "10px", xl: "0px" }} boxShadow={"md"}>
                                                {jobSheet !== null && jobSheet !== undefined &&
                                                    <>
                                                        <InputGroup borderBottomColor={"white"} borderBottomLeftRadius={"0px"} >
                                                            <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderBottomRadius={"0"} borderBottomWidth={"0px"}><FaPoundSign /></InputLeftAddon>
                                                            {/* @ts-ignore */}
                                                            <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={"Labour:            " + jobSheet?.value_labour_nett.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}></Input>
                                                        </InputGroup>

                                                        {jobSheet.value_materials_nett > 0 &&
                                                            <InputGroup borderBottomColor={"white"} borderRadius={"0px"} borderTopColor={"white"} borderTopWidth={"0px"}>
                                                                <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"VAR(--Able-blue)"} borderRadius={"0"} borderTopWidth={"0px"} borderTopColor={"var(--Able-blue)"}><FaPoundSign /></InputLeftAddon>
                                                                {/* @ts-ignore */}
                                                                <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={"Materials:         " + jobSheet?.value_materials_nett.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}></Input>
                                                            </InputGroup>
                                                        }
                                                        <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={"white"}>
                                                            <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderRadius={"0"}><MdSmartphone /></InputLeftAddon>
                                                            {/* @ts-ignore */}
                                                            <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={"VAT (20%):       " + jobSheet?.value_vat.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}></Input>
                                                        </InputGroup>

                                                        {jobSheet.value_discount_requested !== 0 &&
                                                            <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"}>
                                                                <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><AiFillPhone /></InputLeftAddon>
                                                                {/* @ts-ignore */}
                                                                <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={
                                                                    jobSheet.value_discount_is_percentage ?
                                                                        "Discount:        " + jobSheet.value_discount_requested + "%"
                                                                        :
                                                                        "Discount:        " + jobSheet.value_discount_requested.toLocaleString("en-GB", { style: "currency", currency: "GBP" })
                                                                }></Input>
                                                            </InputGroup>
                                                        }
                                                    </>
                                                }
                                                {/* @ts-ignore */}
                                                <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} >
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><AiFillPhone /></InputLeftAddon>
                                                    {/* @ts-ignore */}
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={"Total:                " + jobSheet?.value_gross.toLocaleString("en-GB", { style: "currency", currency: "GBP" })} borderBottomWidth={jobSheet?.value_nte_agreed > 0 ? "0px" : "1px"}></Input>
                                                </InputGroup>
                                                {(jobSheet !== null && jobSheet !== undefined) &&
                                                    <>
                                                        {(jobSheet?.value_nte_agreed !== null && jobSheet?.value_nte_agreed > 0) &&
                                                            <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomLeftRadius={"lg"}>
                                                                <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderRadius={"1"} borderBottomLeftRadius={"lg"}><MdSmartphone /></InputLeftAddon>

                                                                <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={"Agreed Limit:   " + jobSheet?.value_vat.toLocaleString("en-GB", { style: "currency", currency: "GBP" })}></Input>
                                                            </InputGroup>
                                                        }
                                                    </>

                                                }
                                            </Flex>
                                        </Flex>
                                        {jobSheet?.contact_caller_id !== jobSheet?.contact_site_id &&
                                            <Flex direction={"column"} width={{ base: "100%", xl: "400px" }} marginTop={{ base: "10px", xl: "-60px" }} marginLeft={"0px"}boxShadow={"md"}>

                                                <InputGroup borderBottomColor={"white"} borderBottomLeftRadius={"0px"} >
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderBottomRadius={"0"}><BsPersonFill /></InputLeftAddon>
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={" " + siteContact?.Salutation + " " + siteContact?.FirstName + " " + siteContact?.LastName + " (" + siteContact?.Role + ") "}></Input>
                                                </InputGroup>
                                                {/* 
                                                <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={"white"}>
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderTopColor={"var(--Able-blue)"} borderRadius={"0"}><MdSmartphone /></InputLeftAddon>
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={siteContact?.MobileNumber ? " " + siteContact?.MobileNumber : " N/A"}></Input>
                                                </InputGroup>

                                                <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={"white"}>
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><AiFillPhone /></InputLeftAddon>
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={siteContact?.LandLine ? " " + siteContact?.LandLine : " N/A"}></Input>
                                                </InputGroup>
                                                */}
                                                <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={"white"}>
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><IoWarning /></InputLeftAddon>
                                                    {jobSheet?.is_site_contact_billing_authorised ?
                                                        <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={" The site contact is authorised"}></Input>
                                                        :
                                                        <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={" The site contact is not authorised"}></Input>
                                                    }
                                                </InputGroup>
                                                <InputGroup marginTop={"-10px"} borderTopColor={"white"} borderTopWidth={"0px"}>
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><AiFillPhone /></InputLeftAddon>
                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={" to make financial decisions"}></Input>
                                                </InputGroup>
                                            </Flex>
                                        }
                                        <Flex direction={{ base: "column", xl: "row" }} justifyContent={"left"} marginTop={"10px"} width={{ base: "100%", xl: "auto" }} >
                                            <Flex direction={{ base: "column", xl: "column" }} width={{ base: "100%", xl: "400px" }} boxShadow={"base"} paddingBottom={wr?.IsSigned ? "0px": "5px"}>
                                                <InputGroup marginTop={"3px"} borderBottomRadius={"lg"}>
                                                    <InputLeftAddon bg={"var(--Able-blue)"} color={"white"} borderBottomLeftRadius={wr?.IsSigned ? "0" : "lg"} borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"}><BsFillClipboardDataFill /></InputLeftAddon>
                                                    {/* @ts-ignore */}
                                                    {wr !== null && wr !== undefined ?
                                                        <>
                                                            {!wr.IsSigned ?
                                                                <>
                                                                    <Input width={{ base: "100%", xl: "375px" }} contentEditable={false} readOnly={true} value={"Work Report has not been signed"} borderLeftRadius={0} fontSize={{base: "sm", lg: "md"}}></Input> 
                                                                </>

                                                                :
                                                                <>
                                                                    <Input width={{ base: "100%", xl: "375px" }} borderBottomWidth={"0px"} contentEditable={false} readOnly={true} value={"Work Report has been signed"} borderLeftRadius={"lg"} fontSize={{base: "sm", lg: "md"}}></Input>
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <Input pointerEvents={"none"} width={{ base: "100%", xl: "375px" }} contentEditable={false} readOnly={true} value={"Work Report has not been signed"} borderLeftRadius={0}></Input>
                                                    }
                                                </InputGroup>
                                                {wr !== null && wr !== undefined &&
                                                    <>
                                                        <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={"white"}>


                                                            {wr.IsSigned &&
                                                                <>
                                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderRadius={"0"}><BsFillHouseFill /></InputLeftAddon>
                                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={
                                                                        (wr.SignedDate)?.split("").slice(11, 19).join("") + "  " +
                                                                        (wr.SignedDate)?.split("").slice(8, 10).join("") + "/" +
                                                                        (wr.SignedDate)?.split("").slice(5, 7).join("") + "/" +
                                                                        (wr.SignedDate)?.split("").slice(0, 4).join("")}
                                                                    ></Input>
                                                                </>
                                                            }
                                                        </InputGroup>

                                                        <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"}>

                                                            {wr.IsSigned &&
                                                                <>
                                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><BsFillHouseFill /></InputLeftAddon>
                                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={"Signed by:" + wr.SigneeName}></Input>
                                                                </>
                                                            }
                                                        </InputGroup>
                                                    </>
                                                }
                                            </Flex>
                                            <Flex direction={"column"} marginLeft={{ base: "0px", xl: "10px" }} width={{ base: "100%", xl: "400px" }} marginTop={{ base: "10px", xl: "0px" }} boxShadow={"md"} paddingBottom={rtc?.is_signed ? "0px": "5px"}>
                                                <InputGroup marginTop={"3px"} borderBottomColor={"white"} borderBottomLeftRadius={rtc?.is_signed ? "0px" : "lg"} >
                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"white"} borderBottomLeftRadius={rtc?.is_signed ? "0" : "lg"}><FaFileSignature /></InputLeftAddon>
                                                    {/* @ts-ignore */}
                                                    {rtc !== null && rtc !== undefined &&
                                                        <>
                                                            {!rtc?.is_signed ?
                                                                <>

                                                                    <Input pointerEvents={"none"} borderTopLeftRadius={0} width={{ base: "100%", xl: "375px" }} contentEditable={false} readOnly={true} value={"Right to Cancel has not been signed"} fontSize={{base: "sm", lg: "md"}}
                                                                        borderBottomColor={"var(--chakra-colors-gray-200)"}></Input>
                                                                </>

                                                                :
                                                                <>

                                                                    <Input pointerEvents={"none"} borderLeftRadius={0} width={{ base: "100%", xl: "375px" }} contentEditable={false} readOnly={true} value={"Right to Cancel has been signed"} fontSize={{base: "sm", lg: "md"}}
                                                                        borderBottomColor={"white"}></Input>
                                                                </>
                                                            }
                                                        </>

                                                    }
                                                </InputGroup>
                                                {rtc !== null && rtc !== undefined &&
                                                    <>
                                                        <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"} borderBottomColor={rtc.is_signed ? "white" : "grey"} borderRadius={(rtc.is_signed || rtc.signed?.signee !== undefined) ? 0 : 1}>


                                                            {(rtc.is_signed && rtc.signed?.date !== undefined && rtc.signed.date !== null) &&
                                                                <>
                                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderRadius={"0"}><BsFillHouseFill /></InputLeftAddon>
                                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={
                                                                        (rtc.signed?.date)?.split("").slice(11, 19).join("") + " " +
                                                                        (rtc.signed?.date)?.split("").slice(8, 10).join("") + "/" +
                                                                        (rtc.signed?.date)?.split("").slice(5, 7).join("") + "/" +
                                                                        (rtc.signed?.date)?.split("").slice(0, 4).join("") + " "
                                                                        }
                                                                        borderRadius={0}
                                                                    ></Input>
                                                                </>
                                                            }
                                                        </InputGroup>

                                                        <InputGroup marginTop={"-3px"} borderTopColor={"white"} borderTopWidth={"0px"}>

                                                            {(rtc.is_signed && rtc.signed?.signee !== undefined && rtc.signed?.signee !== null) &&
                                                                <>
                                                                    <InputLeftAddon borderRightColor={"var(--Able-yellow)"} borderRightWidth={"2px"} bg={"var(--Able-blue)"} color={"var(--Able-blue)"} borderTopColor={"var(--Able-blue)"} borderTopRadius={"0"}><BsFillHouseFill /></InputLeftAddon>
                                                                    <Input pointerEvents={"none"} contentEditable={false} readOnly={true} value={"Signed by: " + rtc.signed?.signee} borderTopRadius={0} borderBottomLeftRadius={0}></Input>
                                                                </>
                                                            }
                                                        </InputGroup>
                                                    </>
                                                }

                                            </Flex>

                                        </Flex>
                                        <Flex direction={"row"} pointerEvents={"none"} >
                                            <Card width={{ base: "100%", xl: "810px" }} alignSelf={"right"} marginTop={"20px"} size={"lg"} >
                                                <CardHeader bg={"var(--Able-blue)"} borderTopRadius={"lg"} borderBottomRadius={0} borderBottomColor={"var(--Able-yellow)"} borderBottomWidth={"2px"}>
                                                    <Heading size='md' color={"white"} textAlign={"left"}>Other Details</Heading>
                                                </CardHeader>
                                                <CardBody textColor={"var(--Able-blue)"} textAlign={"left"}>
                                                    <Checkbox isReadOnly isChecked={worksheet?.IsAccountWork}>Customer Has Able Account?</Checkbox>
                                                    <br></br>
                                                    <Checkbox isReadOnly isChecked={(worksheet?.IsOnOrder)}>On Order?</Checkbox>
                                                    <Stack pl={6} mt={1} spacing={1}>
                                                        <Checkbox isReadOnly disabled={!worksheet?.IsOnOrder} isChecked={worksheet?.IsOrderLate}>Is Order Late?</Checkbox>

                                                        {!worksheet?.IsOnOrder ?
                                                            <Input value={"Expected Date of Materials"} disabled={!worksheet?.IsOnOrder} width={"50%"}></Input>
                                                            :
                                                            <Input value={String(moment(worksheet?.Expected, "YYYY-MM-DD").format("DD/MM/YYYY"))} isReadOnly></Input>
                                                        }
                                                    </Stack>
                                                </CardBody>
                                            </Card>
                                        </Flex>
                                        {notes !== null && notes !== undefined && notes.length !== 0 &&
                                            <Flex direction={"row"}>
                                                <Card width={{ base: "100%", xl: "810px" }} alignSelf={"right"} marginTop={"20px"} size={"lg"}>
                                                    <CardHeader bg={"var(--Able-blue)"} borderTopRadius={"lg"} borderBottomRadius={0} borderBottomColor={"var(--Able-yellow)"} borderBottomWidth={"2px"}>
                                                        <Heading size='md' color={"white"} textAlign={"left"}>Notes</Heading>
                                                    </CardHeader>
                                                    <CardBody textColor={"var(--Able-blue)"} textAlign={"left"}>
                                                        {/* map through notes content and display each note */}
                                                        {/* may expand in the future to filter based on tag, for now the API should do enough filtering  */}
                                                        {notes.map((each) =>
                                                            <>
                                                                <Text as={"b"}>{String(moment(each.created, "YYYY-MM-DD h:mm:ss A").format("DD/MM/YYYY - h:mm:ss A"))}</Text>
                                                                <br />
                                                                <Text as={"i"}>{each.message}</Text>
                                                                <br />
                                                                <br />
                                                            </>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Flex>
                                        }
                                    </Fade>
                                </Flex>

                                <Flex direction={"column"} width={{ base: "100%",lg: "50%", xl: "100%" }}  marginLeft={{base: "0px", lg:"10px", xl: "50px"}}>
                                    <Fade in={!loading}>
                                        {/* this is the halfway point where the page should now start to render these cards on the right side of the page */}
                                        <Flex direction={"row"} width={"100%"} marginLeft={{base: "0px", lg: "30px", xl: "0px"}}>
                                            <Flex direction={"column"} width={{ base: "100%", xl: "90%" }}>
                                                <Card width={"100%"} alignSelf={"right"} size={"lg"} marginTop={{base: "0px", lg:"15px", xl: "30px"}}>
                                                    <CardHeader bg={"var(--Able-blue)"} borderTopRadius={"lg"} borderBottomRadius={0} borderBottomColor={"var(--Able-yellow)"} borderBottomWidth={"2px"}>
                                                        <Heading size='lg' color={"white"} textAlign={"left"}>Work to Complete</Heading>
                                                    </CardHeader>
                                                    <CardBody textColor={"var(--Able-blue)"} textAlign={"left"}>


                                                        {wipLines?.map(each =>
                                                            <>
                                                                <Flex direction={"row"} >
                                                                    <Heading size="xs" fontSize={"xl"} >{each.name}</Heading>
                                                                </Flex>
                                                                <Text >{each.price_description}
                                                                    <br />
                                                                </Text>
                                                                <Flex justifyContent={"left"} direction={{ base: "column", lg: "row", wwwwwwwwwwwwwwwwwwwwwwxl: "row" }} width={{ base: "100%", xl: "100%" }} marginTop={{ base: "10px", xl: "-10px" }} marginLeft={{ base: 0, xl: "-15px" }}>

                                                                    {/* <Text>
                                                                        {String(questions.filter((element) => element.id === each.id)[0])}
                                                                    </Text>
                                                                    <Questions id={questions.filter((element) => element.id === each.id)[0].id}
                                                                        content={questions.filter((element) => element.id === each.id)[0].content}
                                                                    /> */}

                                                                    {questions.map((element: IQuestions) => (
                                                                        <>
                                                                            {element.id === each.id && element.content !== undefined && element.content.length > 0 ?
                                                                                <>
                                                                                    <Questions id={element.id} content={element.content} />
                                                                                </>
                                                                                :
                                                                                <br />}
                                                                        </>
                                                                    )
                                                                    )}
                                                                    {each.status < 60 && each.is_assessed === true &&
                                                                        <ToOrderModal {...each} cb={refresh} isPAYE={isPAYE} />
                                                                    }
                                                                    <ReadyModal {...each} cb={refresh} isPAYE={isPAYE} />
                                                                </Flex>

                                                            </>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                            </Flex>
                                        </Flex>
                                    </Fade>
                                </Flex>
                                <Text></Text>
                                <Text></Text>

                            </Flex>

                        </>

                            :
                            <>
                                <Progress value={loadingValue} size={"xs"} marginTop={"20%"} width={{base:"90%", lg: "90%", xl: "90%"}} marginLeft={{base:"20px", lg: "0px", xl:"150px"}}/>
                            </>
                        }
                    </Flex>
                    <Flex justifyContent={"center"} direction={"row"} marginTop={"2%"} marginBottom={"0%"}>
                        <Button leftIcon={<MdRemoveCircleOutline />} colorScheme="red" mr={3} onClick={closeJobsheetView}>
                            Close Jobsheet
                        </Button>
                    </Flex>
                </>
                :
                <NotAuthorisedPage />
            }
        </PageContainer >
    )

}