import { useEffect, useState } from "react";
import { IUser } from "./AuthContext";
import { useLocation, useNavigate } from "react-router";
import { IEngineer } from "../interfaces/service/IEngineer";

export function useAuthProvider() {
    const [user, setUser] = useState<IUser | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [signInState, setState] = useState<boolean>(false);
    const navigate = useNavigate();
    const location = useLocation();

    const signIn = async (userName: string, passWord: string, remember: boolean): Promise<boolean> => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                user_name: userName,
                password: passWord,
                remember: remember
            })
        }

        console.log(requestOptions);

        return new Promise<boolean>((result, rej) => {
            fetch('/api/authenticate', requestOptions).then(async res => {
                if (res.status === 200) {
                    let data = await res.json();
                    console.log(data);
                    console.log(data.engineer_id);

                    if (data !== null && data.user !== undefined && data.engineer !== undefined) {
                        setUser({
                            engineer_id: data.user.id,
                            engineer_name: data.user.full_name,
                            contractor_id: data.engineer.contractor_id,
                            contractor_type: data.engineer.contractor?.allocation_style ?? null,
                            contractor_name: data.engineer.name,
                        });
                        setState(true);

                        result(true);
                        
                    } else {
                        result(false);
                        setState(false);
                    }
                } else {
                    result(false);
                    setState(false);
                    console.log("Sign in failed: " + (await res.json()).Message);
                }
            });
        });
    }

    const signOut = () => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }
        fetch('/api/signout', requestOptions).then(res => {
            if (!res.ok) {
                setUser(null); //Backup if sever should fail to sign out
            }
        }).catch(err => {
            console.warn("Failed to sign out session");
        });

        setState(false);

        navigate("/signin");
    }

    useEffect(() => {
        let requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('/api/session', requestOptions).then(async res => {
            if (res.ok) {
                let data = await res.json();
                if (data !== null && data.user !== undefined && data.engineer !== undefined) {
                    setUser({
                        engineer_id: data.user.id,
                        engineer_name: data.user.full_name,
                        contractor_id: data.engineer.contractor_id,
                        contractor_type: data.engineer.contractor?.allocation_style ?? null,
                        contractor_name: data.engineer.name,
                    });
                    setState(true);
                } else {
                    setUser(null);
                }
            } else {
                setUser(null);
            }

            setLoading(false);
        })
    }, []);

    return {user, loading, signInState, signIn, signOut};
}